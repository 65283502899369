"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = _interopRequireDefault(require("@/store"));
var _document = document,
  body = _document.body;
var WIDTH = 1024;
var RATIO = 3;
var _default = exports.default = {
  watch: {
    $route: function $route(route) {
      if (this.device === 'mobile' && this.sidebar.opened) {
        _store.default.dispatch('closeSideBar', {
          withoutAnimation: false
        });
      }
    }
  },
  beforeMount: function beforeMount() {
    window.addEventListener('resize', this.resizeHandler);
  },
  mounted: function mounted() {
    var isMobile = this.isMobile();
    if (isMobile) {
      _store.default.dispatch('toggleDevice', 'mobile');
      _store.default.dispatch('closeSideBar', {
        withoutAnimation: true
      });
    }
  },
  methods: {
    isMobile: function isMobile() {
      var rect = body.getBoundingClientRect();
      return rect.width - RATIO < WIDTH;
    },
    resizeHandler: function resizeHandler() {
      if (!document.hidden) {
        var isMobile = this.isMobile();
        _store.default.dispatch('toggleDevice', isMobile ? 'mobile' : 'desktop');
        if (isMobile) {
          _store.default.dispatch('closeSideBar', {
            withoutAnimation: true
          });
        }
      }
    }
  }
};