"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _profile = require("@/api/profile");
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      hasNotice: false,
      timer: ''
    };
  },
  mounted: function mounted() {
    this.timer = setInterval(this.checkNotice, 30 * 1000);
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timer);
  },
  created: function created() {
    this.checkNotice();
  },
  methods: {
    click: function click() {
      this.$router.push({
        path: '/profile/notice'
      });
    },
    checkNotice: function checkNotice() {
      var _this = this;
      if (this.hasNotice) {
        return;
      }
      (0, _profile.nNotice)().then(function (response) {
        _this.hasNotice = response.data.data > 0;
      });
    }
  }
};