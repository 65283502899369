var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "topic",
          staticStyle: { width: "800px", "margin-left": "50px" },
          attrs: {
            rules: _vm.rules,
            model: _vm.topic,
            "status-icon": "",
            "label-position": "left",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "专题标题", prop: "title" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.topic.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.topic, "title", $$v)
                  },
                  expression: "topic.title",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "专题子标题", prop: "subtitle" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.topic.subtitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.topic, "subtitle", $$v)
                  },
                  expression: "topic.subtitle",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "专题图片", prop: "picUrl" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    headers: _vm.headers,
                    action: _vm.uploadPath,
                    "show-file-list": false,
                    "on-success": _vm.uploadPicUrl,
                    accept: ".jpg,.jpeg,.png,.gif",
                  },
                },
                [
                  _vm.topic.picUrl
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.topic.picUrl },
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon",
                      }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "专题内容", prop: "content" } },
            [
              _c("editor", {
                attrs: { init: _vm.editorInit },
                model: {
                  value: _vm.topic.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.topic, "content", $$v)
                  },
                  expression: "topic.content",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商品低价", prop: "price" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.topic.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.topic, "price", $$v)
                  },
                  expression: "topic.price",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "阅读量", prop: "readCount" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.topic.readCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.topic, "readCount", $$v)
                  },
                  expression: "topic.readCount",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "专题商品", prop: "goods" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCreate()
                    },
                  },
                },
                [_vm._v("创建商品")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.goodsList,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", label: "商品ID", prop: "id" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      property: "picUrl",
                      label: "图片",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("img", {
                              attrs: { src: scope.row.picUrl, width: "60" },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "商品名称", prop: "name" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "商品介绍",
                      prop: "brief",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "操作",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.addVisiable, title: "添加商品" },
          on: {
            "update:visible": function ($event) {
              _vm.addVisiable = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "search" },
            [
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { clearable: "", placeholder: "请输入商品编号" },
                model: {
                  value: _vm.listQuery.goodsSn,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "goodsSn", $$v)
                  },
                  expression: "listQuery.goodsSn",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { clearable: "", placeholder: "请输入商品名称" },
                model: {
                  value: _vm.listQuery.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "name", $$v)
                  },
                  expression: "listQuery.name",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.handleFilter },
                },
                [_vm._v("查找")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: {
                    data: _vm.list,
                    "element-loading-text": "正在查询中。。。",
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "商品ID", prop: "id" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      property: "picUrl",
                      label: "图片",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("img", {
                              attrs: { src: scope.row.picUrl, width: "40" },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "商品名称", prop: "name" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addVisiable = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmAdd } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }