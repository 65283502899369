var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["POST /admin/spec/create"],
                  expression: "['POST /admin/spec/create']",
                },
              ],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.handleCreate },
            },
            [_vm._v("添加选集")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "100px",
              label: "ID",
              prop: "selectedId",
              sortable: "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "选集名称", prop: "selectedName" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", property: "iconUrl", label: "选集图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "60px", height: "60px" },
                      attrs: {
                        src: scope.row.picUrl,
                        "preview-src-list": [scope.row.picUrl],
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "商品数量", prop: "goodsCount" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建时间", prop: "addTime" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作人", prop: "adminName" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "预计分享人", prop: "shareName" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "分销人角色", prop: "role" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(_vm._s(scope.row.role == 1 ? "网红" : "分销商")),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(_vm._s(scope.row.status == 0 ? "未发布" : "已发布")),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "浏览人数",
              prop: "browsePersonCount",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "浏览次数", prop: "browseCount" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "转发次数", prop: "forwardCount" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              width: "250",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 0
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "0" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleRelease(scope.row)
                              },
                            },
                          },
                          [_vm._v("发布")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 0
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "0" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 1
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "0" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleLook(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "0" },
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.statisticsDialogVisiable, title: "统计" },
          on: {
            "update:visible": function ($event) {
              _vm.statisticsDialogVisiable = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.statisticsLoading,
                  expression: "statisticsLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.statisticsList,
                "element-loading-text": "正在查询中。。。",
                height: "450",
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { property: "id", align: "center", label: "ID" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "nickname", align: "center", label: "用户" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "addTime", align: "center", label: "图集" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "addTime", align: "center", label: "款号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "addTime", align: "center", label: "图片" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "addTime",
                  align: "center",
                  label: "开始时间",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "usedTime",
                  align: "center",
                  label: "浏览时长",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "usedTime", align: "center", label: "动作" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            [
              _c("pagination", {
                attrs: {
                  total: _vm.statisticsTotal,
                  page: _vm.statisticsQuery.page,
                  limit: _vm.statisticsQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.statisticsQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.statisticsQuery, "limit", $event)
                  },
                  pagination: _vm.getStatisticsList,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.statisticsDialogVisiable = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }