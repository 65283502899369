"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _comment = require("@/api/comment");
var _order = require("@/api/order");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Secondary package based on el-pagination
var _default = exports.default = {
  name: 'GoodsComment',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        userId: undefined,
        valueId: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      downloadLoading: false,
      replyForm: {
        commentId: 0,
        content: ''
      },
      replyFormVisible: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _comment.listComment)(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleReply: function handleReply(row) {
      this.replyForm = {
        commentId: row.id,
        content: ''
      };
      this.replyFormVisible = true;
    },
    reply: function reply() {
      var _this2 = this;
      (0, _order.replyComment)(this.replyForm).then(function (response) {
        _this2.replyFormVisible = false;
        _this2.$notify.success({
          title: '成功',
          message: '回复成功'
        });
      }).catch(function (response) {
        _this2.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    },
    // 删除
    handleDelete: function handleDelete(row) {
      var _this3 = this;
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _comment.deleteComment)(row).then(function (response) {
          _this3.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });
          var index = _this3.list.indexOf(row);
          _this3.list.splice(index, 1);
        });
      });
    },
    handleDownload: function handleDownload() {
      var _this4 = this;
      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['评论ID', '用户ID', '商品ID', '评论', '评论图片列表', '评论时间'];
        var filterVal = ['id', 'userId', 'valueId', 'content', 'picUrls', 'addTime'];
        excel.export_json_to_excel2(tHeader, _this4.list, filterVal, '商品评论信息');
        _this4.downloadLoading = false;
      });
    }
  }
};