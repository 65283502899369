"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'PreviewTemp',
  props: ['goodsDItem'],
  data: function data() {
    return {
      contents: []
    };
  },
  computed: {
    content: function content() {
      var _this = this;
      this.goodsDItem.children.forEach(function (element, index) {
        var content = '';
        element.content.split('\n').forEach(function (element) {
          if (element === ' ') return;
          content += "<p style='margin: 0;'>";
          content += element + '</p>';
        });
        _this.contents[index] = content;
      });
      return this.contents;
    }
  }
};