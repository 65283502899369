var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-button",
        {
          staticStyle: { margin: "0 0 10px 0" },
          on: { click: _vm.selectUsers },
        },
        [_vm._v("添加用户")]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "正在查询中。。。",
            height: "400",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { property: "id", align: "center", label: "ID" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { property: "userId", align: "center", label: "用户ID" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { property: "nickname", align: "center", label: "用户昵称" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { property: "adminName", align: "center", label: "操作人" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", property: "addTime", label: "操作时间" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleRemove(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getUserList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.selectDialogVisiable, title: "选择用户" },
          on: {
            "update:visible": function ($event) {
              _vm.selectDialogVisiable = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { clearable: "", placeholder: "请输入用户昵称" },
                model: {
                  value: _vm.selectQuery.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectQuery, "username", $$v)
                  },
                  expression: "selectQuery.username",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { clearable: "", placeholder: "请输入手机号" },
                model: {
                  value: _vm.selectQuery.mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectQuery, "mobile", $$v)
                  },
                  expression: "selectQuery.mobile",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.handleFilterSelect },
                },
                [_vm._v("查找")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.selectLoading,
                  expression: "selectLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.selectList,
                "element-loading-text": "正在查询中。。。",
                height: "450",
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  selectable: _vm.isDisabled,
                  disabled: "true",
                  width: "55",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "100px",
                  label: "用户ID",
                  prop: "id",
                  sortable: "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "avatar",
                  label: "用户头像",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.avatar
                          ? _c("img", {
                              attrs: { src: scope.row.avatar, width: "80" },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "用户昵称", prop: "nickname" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "用户名", prop: "username" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "手机号码", prop: "mobile" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "注册时间", prop: "addTime" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "status",
                  align: "center",
                  label: "是否选择",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type: scope.row.selected ? "success" : "info",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.selected ? "已选择" : "未选择")
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            [
              _c("pagination", {
                attrs: {
                  total: _vm.selectTotal,
                  page: _vm.selectQuery.page,
                  limit: _vm.selectQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.selectQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.selectQuery, "limit", $event)
                  },
                  pagination: _vm.handlePaginChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCancelSelect } }, [
                _vm._v("返 回"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSubmitSelect },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }