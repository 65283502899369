var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: { model: _vm.dataForm, "label-width": "90px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "公司简介", prop: "detail" } },
            [
              _c("tinymce", {
                attrs: { width: 680, height: 800 },
                model: {
                  value: _vm.dataForm.detail,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "detail", $$v)
                  },
                  expression: "dataForm.detail",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.update } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }