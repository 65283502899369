var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.dataForm,
            "label-width": "90px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选集名称", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "375px" },
                model: {
                  value: _vm.dataForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "name", $$v)
                  },
                  expression: "dataForm.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "选集图片", prop: "picUrl" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    headers: _vm.headers,
                    action: _vm.uploadPath,
                    "show-file-list": false,
                    "on-success": _vm.uploadPicUrl,
                  },
                },
                [
                  _vm.dataForm.picUrl
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.dataForm.picUrl },
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon",
                      }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "分享人角色", prop: "role" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.dataForm.role,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "role", $$v)
                    },
                    expression: "dataForm.role",
                  },
                },
                [
                  _c(
                    "el-radio",
                    { staticStyle: { width: "60px" }, attrs: { label: 1 } },
                    [_vm._v("网红")]
                  ),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("分销商")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "预计分享人", prop: "user" } },
            [
              _c("el-input", {
                staticStyle: { width: "375px" },
                model: {
                  value: _vm.dataForm.shareName,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "shareName", $$v)
                  },
                  expression: "dataForm.shareName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.status !== "create"
            ? _c(
                "el-form-item",
                { attrs: { label: "选集商品", prop: "content" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { margin: "0 0 10px 0" },
                          attrs: { disabled: _vm.status !== "update" },
                          on: { click: _vm.selectGoods },
                        },
                        [_vm._v("添加商品")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.list,
                            "element-loading-text": "正在查询中。。。",
                            height: "400",
                            border: "",
                            fit: "",
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              property: "goodsSn",
                              align: "center",
                              label: "商品款号",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              property: "goodsName",
                              align: "center",
                              label: "商品名称",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              property: "marketPrice",
                              align: "center",
                              label: "市场售价",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              property: "livePrice",
                              label: "直播价",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "排序",
                              prop: "order",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.seen
                                        ? _c("el-input", {
                                            directives: [
                                              {
                                                name: "focus",
                                                rawName: "v-focus",
                                              },
                                            ],
                                            ref: scope.row.id,
                                            staticClass: "input-width",
                                            on: {
                                              blur: function ($event) {
                                                return _vm.loseFcous(
                                                  scope.$index,
                                                  scope.row
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.order,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "order",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.order",
                                            },
                                          })
                                        : _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "100%",
                                                height: "100%",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.cellClick(
                                                    scope.$index,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(scope.row.order))]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2032678069
                            ),
                          }),
                          _vm._v(" "),
                          _vm.status === "update"
                            ? _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "操作",
                                  "class-name": "small-padding fixed-width",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleRemove(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  573785560
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          total: _vm.total,
                          page: _vm.listQuery.page,
                          limit: _vm.listQuery.limit,
                        },
                        on: {
                          "update:page": function ($event) {
                            return _vm.$set(_vm.listQuery, "page", $event)
                          },
                          "update:limit": function ($event) {
                            return _vm.$set(_vm.listQuery, "limit", $event)
                          },
                          pagination: _vm.selectedGoodsList,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { padding: "20px 0 0 88px" } },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("返回"),
          ]),
          _vm._v(" "),
          _vm.status !== "look"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handleSave },
                },
                [_vm._v("保存")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.selectDialogVisiable, title: "选择商品" },
          on: {
            "update:visible": function ($event) {
              _vm.selectDialogVisiable = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { clearable: "", placeholder: "请输入商品名称" },
                model: {
                  value: _vm.selectQuery.goodsName,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectQuery, "goodsName", $$v)
                  },
                  expression: "selectQuery.goodsName",
                },
              }),
              _vm._v(" "),
              _c("el-cascader", {
                staticClass: "filter-item",
                attrs: {
                  options: _vm.categoryList,
                  "expand-trigger": "hover",
                  clearable: "",
                  placeholder: "请选择商品分类",
                },
                on: { change: _vm.handleCategoryChange },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.handleFilterSelect },
                },
                [_vm._v("查找")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.selectLoading,
                  expression: "selectLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.selectList,
                "element-loading-text": "正在查询中。。。",
                height: "450",
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  selectable: _vm.isDisabled,
                  disabled: "true",
                  width: "55",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "id", align: "center", label: "商品ID" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "name", align: "center", label: "商品名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "iconUrl",
                  label: "商品图片",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          attrs: { src: scope.row.picUrl, width: "40" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "counterPrice",
                  align: "center",
                  label: "市场售价",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "stock",
                  align: "center",
                  label: "商品库存",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "status",
                  align: "center",
                  label: "是否选择",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type: scope.row.selected ? "success" : "info",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.selected ? "已选择" : "未选择")
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            [
              _c("pagination", {
                attrs: {
                  total: _vm.selectTotal,
                  page: _vm.selectQuery.page,
                  limit: _vm.selectQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.selectQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.selectQuery, "limit", $event)
                  },
                  pagination: _vm.handlePaginChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCancelSelect } }, [
                _vm._v("返 回"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSubmitSelect },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }