var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pic_template",
      style: {
        left: _vm.detail.left + "px",
        top: _vm.detail.top + "px",
        height: _vm.detail.height + "px",
      },
      on: { mousedown: _vm.dragPicDown, mousemove: _vm.dragPicMove },
    },
    [
      _c("edit-pic-temp", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.editPic,
            expression: "editPic",
          },
        ],
        attrs: { ratio: _vm.aspectRatio, "pic-size": _vm.detail },
      }),
      _vm._v(" "),
      _c("img", {
        style: {
          width: _vm.detail.width + "px",
          height: _vm.detail.height + "px",
        },
        attrs: { src: _vm.detail.url },
        on: { click: _vm.isEditPic },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }