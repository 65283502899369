"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createCoupon = createCoupon;
exports.deleteCoupon = deleteCoupon;
exports.listCoupon = listCoupon;
exports.listCouponUser = listCouponUser;
exports.readCoupon = readCoupon;
exports.updateCoupon = updateCoupon;
var _request = _interopRequireDefault(require("@/utils/request"));
function listCoupon(query) {
  return (0, _request.default)({
    url: '/coupon/list',
    method: 'get',
    params: query
  });
}
function createCoupon(data) {
  return (0, _request.default)({
    url: '/coupon/create',
    method: 'post',
    data: data
  });
}
function readCoupon(id) {
  return (0, _request.default)({
    url: '/coupon/read',
    method: 'get',
    params: {
      id: id
    }
  });
}
function updateCoupon(data) {
  return (0, _request.default)({
    url: '/coupon/update',
    method: 'post',
    data: data
  });
}
function deleteCoupon(data) {
  return (0, _request.default)({
    url: '/coupon/delete',
    method: 'post',
    data: data
  });
}
function listCouponUser(query) {
  return (0, _request.default)({
    url: '/coupon/listuser',
    method: 'get',
    params: query
  });
}