"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _stat = require("@/api/stat");
var _histogram = _interopRequireDefault(require("v-charts/lib/histogram"));
//
//
//
//
//
//
var _default = exports.default = {
  name: 'StatUser',
  components: {
    VeHistogram: _histogram.default
  },
  data: function data() {
    return {
      chartData: {},
      chartSettings: {},
      chartExtend: {}
    };
  },
  created: function created() {
    var _this = this;
    (0, _stat.statUser)().then(function (response) {
      _this.chartData = response.data.data;
      _this.chartSettings = {
        labelMap: {
          'users': '用户增长数'
        }
      };
      _this.chartExtend = {
        xAxis: {
          boundaryGap: true
        },
        series: {
          label: {
            show: true,
            position: 'top'
          }
        }
      };
    });
  }
};