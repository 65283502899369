"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _editorImgDrag = _interopRequireDefault(require("./components/editorImgDrag"));
var _storage = require("@/api/storage");
require("../../../public/tinymce4.7.5/langs/zh_CN");
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'Tinymce',
  components: {
    editorImgDrag: _editorImgDrag.default
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    toolbar: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    menubar: {
      type: String,
      default: 'file edit insert view format table'
    },
    height: {
      type: Number,
      required: false,
      default: 360
    },
    width: {
      type: Number,
      required: false,
      default: 720
    }
  },
  data: function data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id || 'vue-tinymce-' + +new Date()
    };
  },
  watch: {
    value: function value(val) {
      var _this2 = this;
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(function () {
          return window.tinymce.get(_this2.tinymceId).setContent(val);
        });
      }
    }
  },
  mounted: function mounted() {
    this.initTinymce();
  },
  activated: function activated() {
    this.initTinymce();
  },
  deactivated: function deactivated() {
    this.destroyTinymce();
  },
  destroyed: function destroyed() {
    this.destroyTinymce();
  },
  methods: {
    initTinymce: function initTinymce() {
      var _this3 = this;
      var _this = this;
      window.tinymce.init({
        selector: "#".concat(this.tinymceId),
        // 语言
        language: 'zh_CN',
        // 高度
        height: this.height,
        // 宽度
        width: this.width,
        // 自动转换URL:关闭
        convert_urls: false,
        // 工具栏
        toolbar: ['searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample', 'hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen'],
        // 指定需加载的插件
        plugins: ['advlist anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount'],
        // 编辑器初始化完成回调
        init_instance_callback: function init_instance_callback(editor) {
          if (_this.value) {
            editor.setContent(_this.value);
          }
          _this.hasInit = true;
          editor.on('NodeChange Change KeyUp SetContent', function () {
            _this3.hasChange = true;
            _this3.$emit('input', editor.getContent());
          });
        },
        // 编辑器图片上传
        images_upload_handler: function images_upload_handler(blobInfo, success, failure) {
          var formData = new FormData();
          formData.append('file', blobInfo.blob());
          (0, _storage.createStorage)(formData).then(function (res) {
            success(res.data.data.url + '?imageView/2/w/600');
          }).catch(function () {
            failure('上传失败，请重新上传');
          });
        }
      });
    },
    // 销毁编辑器
    destroyTinymce: function destroyTinymce() {
      if (window.tinymce.get(this.tinymceId)) {
        window.tinymce.get(this.tinymceId).destroy();
      }
    },
    // 设置编辑器内容
    setContent: function setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value);
    },
    // 获取编辑器内容
    getContent: function getContent() {
      window.tinymce.get(this.tinymceId).getContent();
    },
    // 多图上传排序成功
    imageSuccess: function imageSuccess(arr) {
      var _this = this;
      arr.forEach(function (v) {
        window.tinymce.get(_this.tinymceId).insertContent("<img class=\"wscnph\" src=\"".concat(v + '?imageView/2/w/600', "\" >"));
      });
    }
  }
};