"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _aftersale = require("@/api/aftersale");
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var typesMap = {
  0: '退款',
  1: '退款退货',
  2: '换货'
};
var statusMap = {
  101: '售后中',
  102: '已取消',
  201: '已完成',
  202: '已拒绝',
  203: '退货中',
  301: '已退款'
};
var _default = exports.default = {
  name: 'MallAftersaleDetail',
  filters: {
    afterTypesFilter: function afterTypesFilter(type) {
      return typesMap[type];
    },
    orderStatusFilter: function orderStatusFilter(status) {
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      // 订单详情
      afterSaleId: undefined,
      orderId: undefined,
      afterDetail: {},
      afterGoods: [],
      show: true,
      // 发货
      shipForm: {
        id: undefined,
        reply: undefined,
        shipChannel: undefined,
        shipSn: undefined
      },
      channels: [],
      shipDialogVisible: false,
      shipRules: {
        shipChannel: [{
          required: true,
          message: '快递公司不能为空',
          trigger: 'blur'
        }],
        shipSn: [{
          required: true,
          message: '快递编号不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    if (this.$route.query.id) {
      this.afterSaleId = this.$route.query.id;
    }
    this.handleDetail();
    this.getChannel();
  },
  methods: {
    // 详情
    handleDetail: function handleDetail() {
      var _this = this;
      (0, _aftersale.detailAftersale)({
        id: this.afterSaleId
      }).then(function (response) {
        var goods = [];
        goods.push(response.data.data.goods);
        _this.afterGoods = goods;
        _this.afterDetail = response.data.data.aftersale;
        if (_this.afterDetail.status === 101) {
          _this.show = true;
        } else {
          _this.show = false;
        }
      });
    },
    // 审核通过
    agree: function agree() {
      var _this2 = this;
      // 退款退货、换货商家收货信息必填
      if (this.afterDetail.type > 0 && (this.afterDetail.sellerConsignee === '' || this.afterDetail.sellerMobile === '' || this.afterDetail.sellerAddress === '')) {
        this.$message.error('商家收货信息不能为空');
        return false;
      }
      (0, _aftersale.receptAftersale)({
        id: this.afterDetail.id,
        reply: this.afterDetail.reply,
        consignee: this.afterDetail.sellerConsignee,
        mobile: this.afterDetail.sellerMobile,
        address: this.afterDetail.sellerAddress
      }).then(function (response) {
        _this2.$notify.success({
          title: '成功',
          message: '审核通过操作成功'
        });
        _this2.$router.push({
          path: '/mall/aftersale'
        });
      }).catch(function (response) {
        _this2.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    },
    // 审核拒绝
    refund: function refund() {
      var _this3 = this;
      (0, _aftersale.rejectAftersale)({
        id: this.afterDetail.id,
        reply: this.afterDetail.reply,
        consignee: this.afterDetail.sellerConsignee,
        mobile: this.afterDetail.sellerMobile,
        address: this.afterDetail.sellerAddress
      }).then(function (response) {
        _this3.$notify.success({
          title: '成功',
          message: '审核拒绝操作成功'
        });
        _this3.$router.push({
          path: '/mall/aftersale'
        });
      }).catch(function (response) {
        _this3.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    },
    // 发货
    handleShip: function handleShip() {
      var _this4 = this;
      this.shipForm.id = this.afterDetail.id;
      this.shipForm.reply = this.afterDetail.reply;
      this.shipForm.shipChannel = undefined;
      this.shipForm.shipSn = undefined;
      this.shipDialogVisible = true;
      this.$nextTick(function () {
        _this4.$refs['shipForm'].clearValidate();
      });
    },
    confirmShip: function confirmShip() {
      var _this5 = this;
      this.$refs['shipForm'].validate(function (valid) {
        if (valid) {
          (0, _aftersale.rejectAndShip)(_this5.shipForm).then(function (response) {
            _this5.shipDialogVisible = false;
            _this5.$notify.success({
              title: '成功',
              message: '确认发货成功'
            });
            _this5.$router.push({
              path: '/mall/aftersale'
            });
          }).catch(function (response) {
            _this5.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    // 获取快递公司列表
    getChannel: function getChannel() {
      var _this6 = this;
      (0, _order.listChannel)().then(function (response) {
        _this6.channels = response.data.data;
      });
    },
    // 返回
    returnAftersale: function returnAftersale() {
      this.$router.push({
        path: '/mall/aftersale'
      });
    }
  }
};