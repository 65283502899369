"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ImgDrag = _interopRequireDefault(require("@/components/ImgDrag"));
var _storage = require("@/api/storage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'EditorImgDrag',
  components: {
    ImageDrag: _ImgDrag.default
  },
  props: {
    color: {
      type: String,
      default: '#1890ff'
    }
  },
  data: function data() {
    return {
      uploadPath: _storage.uploadPath,
      file_list: [],
      dialogVisible: false
    };
  },
  methods: {
    // 图片拖拽排序、图片上传前格式、大小限制
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      // const type_arr = ['image/jpeg', 'image/png']
      // const type = file.type
      // if (!type_arr.includes(type)) {
      //   this.$message.error('图片格式不正确,只支持jpg和png类型图片')
      //   return false
      // }
      var isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('图片大小不超过10M');
        return false;
      }
      return true;
    },
    // 图片上传成功
    bannerPicSuccess: function bannerPicSuccess(response, file, fileList) {
      if (response.errno === 0) {
        this.file_list.push(response.data.url);
      }
    },
    // 图片上传失败
    uploadError: function uploadError() {
      this.$message.error('上传失败，请重新上传');
    },
    // 拖拽保存图片
    updateFile: function updateFile(val) {
      this.file_list = val;
      console.log(this.file_list);
    },
    // 确认
    handleSubmit: function handleSubmit() {
      this.$emit('success', this.file_list);
      this.file_list = [];
      this.dialogVisible = false;
    }
  }
};