var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "langs" },
    [
      _c(
        "el-input",
        {
          style: _vm.type != "textarea" ? "width:100%" : "width:89.5%",
          attrs: { placeholder: _vm.placeholder, type: _vm.type },
          model: {
            value: _vm.inpShow,
            callback: function ($$v) {
              _vm.inpShow = $$v
            },
            expression: "inpShow",
          },
        },
        [
          _vm.type != "textarea"
            ? _c(
                "el-button",
                {
                  attrs: { slot: "append" },
                  on: { click: _vm.onLanguageVisible },
                  slot: "append",
                },
                [
                  _c("img", {
                    staticStyle: { width: "25px" },
                    attrs: { src: require("@/assets/image/diqiu.png") },
                  }),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.type == "textarea"
        ? _c(
            "el-button",
            {
              staticClass: "lang",
              attrs: { slot: "append" },
              on: { click: _vm.onLanguageVisible },
              slot: "append",
            },
            [
              _c("img", {
                staticStyle: { width: "25px" },
                attrs: { src: require("@/assets/image/diqiu.png") },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "modal-append-to-body": false,
            "append-to-body": true,
            title: "语种编辑",
            width: "700px",
            visible: _vm.languageTranslation,
          },
          on: {
            "update:visible": function ($event) {
              _vm.languageTranslation = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "nameLang",
              attrs: {
                model: _vm.nameLang,
                layout: "horizontal",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "div",
                { staticClass: "tran", on: { click: _vm.trans } },
                [
                  _c(
                    "el-button",
                    { staticClass: "transData", attrs: { type: "primary" } },
                    [_vm._v("一键自动翻译")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.nameLang, function (value, index) {
                return _c(
                  "el-form-item",
                  { key: index, attrs: { label: index, prop: index } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "580px" },
                      attrs: { type: "textarea" },
                      model: {
                        value: _vm.nameLang[index],
                        callback: function ($$v) {
                          _vm.$set(_vm.nameLang, index, $$v)
                        },
                        expression: "nameLang[index]",
                      },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.languageTranslation = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleLanguage },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }