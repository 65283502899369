"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _stat = require("@/api/stat");
var _line = _interopRequireDefault(require("v-charts/lib/line"));
//
//
//
//
//
//
var _default = exports.default = {
  name: 'StatGoods',
  components: {
    VeLine: _line.default
  },
  data: function data() {
    return {
      chartData: {},
      chartSettings: {},
      chartExtend: {}
    };
  },
  created: function created() {
    var _this = this;
    (0, _stat.statGoods)().then(function (response) {
      _this.chartData = response.data.data;
      _this.chartSettings = {
        labelMap: {
          'orders': '订单量',
          'products': '下单货品数量',
          'amount': '下单货品总额'
        }
      };
      _this.chartExtend = {
        xAxis: {
          boundaryGap: true
        }
      };
    });
  }
};