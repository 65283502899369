"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _profile = require("@/api/profile");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ProfilePassword',
  data: function data() {
    var _this = this;
    var validatePass = function validatePass(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    var validatePass2 = function validatePass2(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== _this.dataForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      dataForm: {
        oldPassword: '',
        newPassword: '',
        newPassword2: ''
      },
      rules: {
        oldPassword: [{
          required: true,
          message: '旧密码不能为空',
          trigger: 'blur'
        }],
        newPassword: [{
          required: true,
          message: '新密码不能为空',
          trigger: 'blur'
        }, {
          validator: validatePass,
          trigger: 'blur'
        }],
        newPassword2: [{
          required: true,
          message: '确认密码不能为空',
          trigger: 'blur'
        }, {
          validator: validatePass2,
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    cancel: function cancel() {
      var _this2 = this;
      this.dataForm = {
        oldPassword: '',
        newPassword: '',
        newPassword2: ''
      };
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    change: function change() {
      var _this3 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (!valid) {
          return;
        }
        (0, _profile.changePassword)(_this3.dataForm).then(function (response) {
          _this3.$notify.success({
            title: '成功',
            message: '修改密码成功'
          });
        }).catch(function (response) {
          _this3.$notify.error({
            title: '失败',
            message: response.data.errmsg
          });
        });
      });
    }
  }
};