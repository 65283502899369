"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createAdmin = createAdmin;
exports.deleteAdmin = deleteAdmin;
exports.listAdmin = listAdmin;
exports.readminAdmin = readminAdmin;
exports.updateAdmin = updateAdmin;
var _request = _interopRequireDefault(require("@/utils/request"));
function listAdmin(query) {
  return (0, _request.default)({
    url: '/admin/list',
    method: 'get',
    params: query
  });
}
function createAdmin(data) {
  return (0, _request.default)({
    url: '/admin/create',
    method: 'post',
    data: data
  });
}
function readminAdmin(data) {
  return (0, _request.default)({
    url: '/admin/readmin',
    method: 'get',
    data: data
  });
}
function updateAdmin(data) {
  return (0, _request.default)({
    url: '/admin/update',
    method: 'post',
    data: data
  });
}
function deleteAdmin(data) {
  return (0, _request.default)({
    url: '/admin/delete',
    method: 'post',
    data: data
  });
}