import { render, staticRenderFns } from "./notice.vue?vue&type=template&id=5d0a5398"
import script from "./notice.vue?vue&type=script&lang=js"
export * from "./notice.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d0a5398')) {
      api.createRecord('5d0a5398', component.options)
    } else {
      api.reload('5d0a5398', component.options)
    }
    module.hot.accept("./notice.vue?vue&type=template&id=5d0a5398", function () {
      api.rerender('5d0a5398', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/profile/notice.vue"
export default component.exports