"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selected = require("@/api/selected");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SelectAnthology',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        sort: 'id',
        order: 'desc'
      },
      // 统计
      statisticsDialogVisiable: false,
      statisticsLoading: false,
      statisticsList: [],
      getCount: 0,
      usedCount: 0,
      statisticsTotal: 0,
      statisticsQuery: {
        page: 1,
        limit: 20,
        sort: 'id',
        order: 'asc',
        couponId: 0
      },
      downloadLoading: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _selected.fetchList)(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    // 新增选集
    handleCreate: function handleCreate() {
      this.$router.push({
        path: '/select/addAnthology'
      });
    },
    // 编辑
    handleEdit: function handleEdit(row) {
      this.$router.push({
        path: '/select/editAnthology?id=' + row.selectedId + '&type=0'
      });
    },
    // 查看
    handleLook: function handleLook(row) {
      this.$router.push({
        path: '/select/lookAnthology?id=' + row.selectedId + '&type=1'
      });
    },
    // 发布
    handleRelease: function handleRelease(row) {
      var _this2 = this;
      this.$confirm('是否要进行发布操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _selected.activateSelected)({
          selectedId: row.selectedId
        }).then(function (response) {
          _this2.$notify.success({
            title: '成功',
            message: '删除成功'
          });
          _this2.getList();
        }).catch(function (response) {
          _this2.$notify.error({
            title: '失败',
            message: response.data.errmsg
          });
        });
      });
    },
    // 删除
    handleDelete: function handleDelete(row) {
      var _this3 = this;
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _selected.deleteSelected)({
          selectedId: row.selectedId
        }).then(function (response) {
          _this3.$notify.success({
            title: '成功',
            message: '删除成功'
          });
          var index = _this3.list.indexOf(row);
          _this3.list.splice(index, 1);
        }).catch(function (response) {
          _this3.$notify.error({
            title: '失败',
            message: response.data.errmsg
          });
        });
      });
    },
    // 统计
    handleStatistics: function handleStatistics(row) {
      this.statisticsQuery.page = 1;
      this.statisticsQuery.couponId = row.id;
      this.statisticsList = [];
      this.statisticsTotal = 0;
      this.statisticsDialogVisiable = true;
      this.getStatisticsList();
    },
    // 选择商品：获取导入新商品列表
    getStatisticsList: function getStatisticsList() {
      //   this.statisticsLoading = true
      //   statisticsCoupon(this.statisticsQuery)
      //     .then(response => {
      //       this.statisticsList = response.data.data.list
      //       this.getCount = response.data.data.getCount
      //       this.usedCount = response.data.data.usedCount
      //       this.statisticsTotal = response.data.data.getCount
      //       this.statisticsLoading = false
      //     })
      //     .catch(response => {
      //       this.statisticsList = []
      //       this.getCount = 0
      //       this.usedCount = 0
      //       this.statisticsTotal = 0
      //       this.statisticsLoading = false
      //     })
    }
  }
};