"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'TextEditTool',
  props: ['detail'],
  data: function data() {
    return {
      fontSizeLib: ['12', '14', '16', '18', '20', '22', '24', '26', '28', '30', '32', '34', '36', '38', '40']
    };
  },
  methods: {
    clickEditBox: function clickEditBox(ev) {
      ev.stopPropagation();
    },
    // 文字粗体
    textBold: function textBold() {
      if (this.detail.EditFontWeight === 700) {
        this.detail.EditFontWeight = 400;
      } else {
        this.detail.EditFontWeight = 700;
      }
    },
    // 文字斜体
    textItalic: function textItalic() {
      if (this.detail.EditFontStyle === 'italic') {
        this.detail.EditFontStyle = 'normal';
      } else {
        this.detail.EditFontStyle = 'italic';
      }
    }
  }
};