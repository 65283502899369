"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _config = require("@/api/config");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ConfigWx',
  data: function data() {
    return {
      dataForm: {
        litemall_wx_index_new: 0,
        litemall_wx_index_hot: 0,
        litemall_wx_index_brand: 0,
        litemall_wx_index_topic: 0,
        litemall_wx_catlog_list: 0,
        litemall_wx_catlog_goods: 0,
        litemall_wx_share: false
      },
      rules: {
        litemall_wx_index_new: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        litemall_wx_index_hot: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        litemall_wx_index_brand: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        litemall_wx_index_topic: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        litemall_wx_catlog_list: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        litemall_wx_catlog_goods: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;
      (0, _config.listWx)().then(function (response) {
        _this.dataForm = response.data.data;
      });
    },
    cancel: function cancel() {
      this.init();
    },
    update: function update() {
      var _this2 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (!valid) {
          return false;
        }
        _this2.doUpdate();
      });
    },
    doUpdate: function doUpdate() {
      var _this3 = this;
      (0, _config.updateWx)(this.dataForm).then(function (response) {
        _this3.$notify.success({
          title: '成功',
          message: '小程序配置成功'
        });
      }).catch(function (response) {
        _this3.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    }
  }
};