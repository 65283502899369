var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "text_template",
      style: {
        width: _vm.detail.width + "px",
        height: _vm.detail.height + "px",
        top: _vm.detail.top + "px",
        left: _vm.detail.left + "px",
      },
      on: { click: _vm.changeEditStatus },
    },
    [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.detail.content,
            expression: "detail.content",
          },
        ],
        staticClass: "text-textarea",
        style: {
          resize: _vm.isEditText ? "both" : "none",
          overflowY: _vm.isEditText ? "auto" : "hidden",
          width: _vm.detail.width + "px",
          height: _vm.detail.height + "px",
          color: _vm.detail.color,
          backgroundColor: _vm.detail.bgColor,
          fontWeight: _vm.detail.fontWeight,
          fontStyle: _vm.detail.fontStyle,
          fontSize: _vm.detail.fontSize + "px",
        },
        attrs: {
          id: "",
          name: "",
          readonly: _vm.isEditText ? false : "readonly",
        },
        domProps: { value: _vm.detail.content },
        on: {
          mousedown: _vm.dragTextDown,
          mousemove: _vm.dragTextMove,
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.detail, "content", $event.target.value)
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }