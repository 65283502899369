var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { attrs: { trigger: "click" }, on: { command: _vm.handleSetSize } },
    [
      _c("div", [_c("i", { staticClass: "el-icon-rank" })]),
      _vm._v(" "),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-dropdown-item",
            { attrs: { disabled: _vm.size === "medium", command: "medium" } },
            [_vm._v("Medium")]
          ),
          _vm._v(" "),
          _c(
            "el-dropdown-item",
            { attrs: { disabled: _vm.size === "small", command: "small" } },
            [_vm._v("Small")]
          ),
          _vm._v(" "),
          _c(
            "el-dropdown-item",
            { attrs: { disabled: _vm.size === "mini", command: "mini" } },
            [_vm._v("Mini")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }