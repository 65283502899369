"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _region = require("@/api/region");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'MallRegion',
  filters: {
    typeFilter: function typeFilter(status) {
      var typeMap = {
        '1': '省',
        '2': '市',
        '3': '区'
      };
      return typeMap[status];
    }
  },
  data: function data() {
    return {
      list: [],
      listLoading: true,
      downloadLoading: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _region.listRegion)().then(function (response) {
        _this.list = response.data.data.list;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.listLoading = false;
      });
    }
  }
};