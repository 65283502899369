var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video_base" }, [
    _c("video", { attrs: { controls: "controls", src: _vm.url } }, [
      _vm._v("你的浏览器不支持视频播放"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }