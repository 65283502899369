"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.statGoods = statGoods;
exports.statOrder = statOrder;
exports.statUser = statUser;
var _request = _interopRequireDefault(require("@/utils/request"));
function statUser(query) {
  return (0, _request.default)({
    url: '/stat/user',
    method: 'get',
    params: query
  });
}
function statOrder(query) {
  return (0, _request.default)({
    url: '/stat/order',
    method: 'get',
    params: query
  });
}
function statGoods(query) {
  return (0, _request.default)({
    url: '/stat/goods',
    method: 'get',
    params: query
  });
}