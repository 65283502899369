"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'StarInputTag',
  model: {
    prop: 'tagList',
    event: 'input'
  },
  props: {
    tagList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    theme: {
      type: String,
      default: '+ 新标签'
    }
  },
  data: function data() {
    return {
      inputVisible: false,
      inputValue: '',
      num: -1,
      words: ''
    };
  },
  computed: {
    dynamicTags: {
      get: function get() {
        return this.tagList;
      },
      set: function set(tagList) {
        this.$emit('input', tagList);
      }
    }
  },
  mounted: function mounted() {
    console.log();
  },
  methods: {
    // 数组去重
    unique: function unique(arr) {
      var x = new Set(arr);
      return (0, _toConsumableArray2.default)(x);
    },
    handleClose: function handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    showInput: function showInput() {
      var _this = this;
      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.dynamicTags = this.unique(this.dynamicTags);
      this.inputVisible = false;
      this.inputValue = '';
    },
    editTag: function editTag(tag, index) {
      var _this2 = this;
      this.num = index;
      this.$nextTick(function (_) {
        _this2.$refs.editInput[0].focus();
      });
      this.words = tag;
    },
    handleInput: function handleInput(tag, index) {
      var words = this.words;
      if (words) {
        this.dynamicTags[index] = words;
      }
      this.dynamicTags = this.unique(this.dynamicTags);
      this.words = '';
      this.num = -1;
    }
  }
};