var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "TextEditBox",
      style: {
        top: _vm.detail.EditTop - 40 + "px",
        left: _vm.detail.EditLeft + "px",
      },
      on: { click: _vm.clickEditBox },
    },
    [
      _c("i", {
        staticClass: "iconfont icon-bold textTool",
        on: { click: _vm.textBold },
      }),
      _vm._v(" "),
      _c("i", {
        staticClass: "iconfont icon-italic textTool",
        on: { click: _vm.textItalic },
      }),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.detail.EditFontSize,
              expression: "detail.EditFontSize",
            },
          ],
          attrs: { id: "", name: "" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.detail,
                "EditFontSize",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        _vm._l(_vm.fontSizeLib, function (item, index) {
          return _c("option", { key: index, domProps: { value: item } }, [
            _vm._v(_vm._s(item)),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("i", {
        staticClass: "iconfont icon-ziyuan",
        attrs: { title: "字体颜色" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { title: "字体颜色" } },
        [
          _c("colorPicker", {
            staticClass: "textColorPicker",
            model: {
              value: _vm.detail.EditColor,
              callback: function ($$v) {
                _vm.$set(_vm.detail, "EditColor", $$v)
              },
              expression: "detail.EditColor",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("i", {
        staticClass: "iconfont icon-background",
        attrs: { title: "背景颜色" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { title: "背景颜色" } },
        [
          _c("colorPicker", {
            staticClass: "textBgColorPicker",
            model: {
              value: _vm.detail.EditBgColor,
              callback: function ($$v) {
                _vm.$set(_vm.detail, "EditBgColor", $$v)
              },
              expression: "detail.EditBgColor",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }