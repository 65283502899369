"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _text = _interopRequireDefault(require("./components/text"));
var _pic = _interopRequireDefault(require("./components/pic"));
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'TextContent',
  components: {
    InnerText: _text.default,
    PicImgBase: _pic.default
  },
  props: ['children']
};