"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchDeleteTopic = batchDeleteTopic;
exports.createTopic = createTopic;
exports.deleteTopic = deleteTopic;
exports.listTopic = listTopic;
exports.readTopic = readTopic;
exports.updateTopic = updateTopic;
var _request = _interopRequireDefault(require("@/utils/request"));
function listTopic(query) {
  return (0, _request.default)({
    url: '/topic/list',
    method: 'get',
    params: query
  });
}
function createTopic(data) {
  return (0, _request.default)({
    url: '/topic/create',
    method: 'post',
    data: data
  });
}
function readTopic(query) {
  return (0, _request.default)({
    url: '/topic/read',
    method: 'get',
    params: query
  });
}
function updateTopic(data) {
  return (0, _request.default)({
    url: '/topic/update',
    method: 'post',
    data: data
  });
}
function deleteTopic(data) {
  return (0, _request.default)({
    url: '/topic/delete',
    method: 'post',
    data: data
  });
}
function batchDeleteTopic(data) {
  return (0, _request.default)({
    url: '/topic/batch-delete',
    method: 'post',
    data: data
  });
}