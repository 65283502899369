"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createStorage = createStorage;
exports.deleteStorage = deleteStorage;
exports.listStorage = listStorage;
exports.readStorage = readStorage;
exports.updateStorage = updateStorage;
exports.uploadPath = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
function listStorage(query) {
  return (0, _request.default)({
    url: '/storage/list',
    method: 'get',
    params: query
  });
}
function createStorage(data) {
  return (0, _request.default)({
    url: '/storage/create',
    method: 'post',
    data: data
  });
}
function readStorage(data) {
  return (0, _request.default)({
    url: '/storage/read',
    method: 'get',
    data: data
  });
}
function updateStorage(data) {
  return (0, _request.default)({
    url: '/storage/update',
    method: 'post',
    data: data
  });
}
function deleteStorage(data) {
  return (0, _request.default)({
    url: '/storage/delete',
    method: 'post',
    data: data
  });
}
var uploadPath = exports.uploadPath = process.env.VUE_APP_BASE_API + '/storage/create';