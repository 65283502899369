var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-button",
        {
          style: { background: _vm.color, borderColor: _vm.color },
          attrs: { icon: "el-icon-upload", size: "mini", type: "primary" },
          on: {
            click: function ($event) {
              _vm.dialogVisible = true
            },
          },
        },
        [_vm._v("上传图片")]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "上传图片",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("image-drag", {
            attrs: {
              limit: 10,
              list: _vm.file_list,
              multiple: true,
              action: _vm.uploadPath,
              "on-error": _vm.uploadError,
              "on-success": _vm.bannerPicSuccess,
              "before-upload": _vm.beforeAvatarUpload,
            },
            on: { update: _vm.updateFile },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }