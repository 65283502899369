var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "edit_text_temp",
      style: {
        width: _vm.detail.EditWidth + "px",
        top: _vm.detail.EditTop + "px",
        left: _vm.detail.EditLeft + "px",
      },
    },
    [
      _c("div", { staticClass: "edittopBorder" }),
      _vm._v(" "),
      _c("div", {
        staticClass: "editrightBorder",
        style: { height: _vm.detail.EditHeight + "px" },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "editleftBorder",
        style: { height: _vm.detail.EditHeight + "px" },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "editbottomBorder",
        style: { top: _vm.detail.EditHeight - 0.5 + "px" },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "edit_point",
        staticStyle: { left: "-4px", top: "-4px", cursor: "nw-resize" },
        on: {
          mousedown: _vm.mousedownPoint,
          mousemove: function ($event) {
            return _vm.resizeBox($event, -1, -1)
          },
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "edit_point rightTop",
        staticStyle: { right: "-4px", top: "-4px", cursor: "ne-resize" },
        on: {
          mousedown: _vm.mousedownPoint,
          mousemove: function ($event) {
            return _vm.resizeBox($event, 1, -1)
          },
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "edit_point",
        staticStyle: { left: "-4px", cursor: "sw-resize" },
        style: { top: _vm.detail.EditHeight - 4 + "px" },
        on: {
          mousedown: _vm.mousedownPoint,
          mousemove: function ($event) {
            return _vm.resizeBox($event, -1, 1)
          },
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "edit_point",
        staticStyle: { right: "-4px", cursor: "se-resize" },
        style: { top: _vm.detail.EditHeight - 4 + "px" },
        on: {
          mousedown: _vm.mousedownPoint,
          mousemove: function ($event) {
            return _vm.resizeBox($event, 1, 1)
          },
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "edit_point",
        staticStyle: { left: "-4px", cursor: "w-resize" },
        style: { top: _vm.detail.EditHeight / 2 - 4 + "px" },
        on: {
          mousedown: _vm.mousedownPoint,
          mousemove: function ($event) {
            return _vm.resizeBox($event, -1, 0)
          },
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "edit_point",
        staticStyle: { right: "-4px", cursor: "e-resize" },
        style: { top: _vm.detail.EditHeight / 2 - 4 + "px" },
        on: {
          mousedown: _vm.mousedownPoint,
          mousemove: function ($event) {
            return _vm.resizeBox($event, 1, 0)
          },
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "edit_point",
        staticStyle: { top: "-4px", cursor: "n-resize" },
        style: { left: _vm.detail.EditWidth / 2 - 4 + "px" },
        on: {
          mousedown: _vm.mousedownPoint,
          mousemove: function ($event) {
            return _vm.resizeBox($event, 0, -1)
          },
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "edit_point bottomCenter",
        staticStyle: { cursor: "s-resize" },
        style: {
          left: _vm.detail.EditWidth / 2 - 4 + "px",
          top: _vm.detail.EditHeight - 4 + "px",
        },
        on: {
          mousedown: _vm.mousedownPoint,
          mousemove: function ($event) {
            return _vm.resizeBox($event, 0, 1)
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }