"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteOrder = deleteOrder;
exports.detailOrder = detailOrder;
exports.listChannel = listChannel;
exports.listOrder = listOrder;
exports.listShipGoods = listShipGoods;
exports.refundOrder = refundOrder;
exports.replyComment = replyComment;
exports.shipOrder = shipOrder;
exports.updateOrderAddress = updateOrderAddress;
exports.updateOrderPrice = updateOrderPrice;
var _request = _interopRequireDefault(require("@/utils/request"));
var _qs = _interopRequireDefault(require("qs"));
function listOrder(query) {
  return (0, _request.default)({
    url: '/order/list',
    method: 'get',
    params: query,
    paramsSerializer: function paramsSerializer(params) {
      return _qs.default.stringify(params, {
        arrayFormat: 'repeat'
      });
    }
  });
}
function detailOrder(id) {
  return (0, _request.default)({
    url: '/order/detail',
    method: 'get',
    params: {
      id: id
    }
  });
}
function shipOrder(data) {
  return (0, _request.default)({
    url: '/order/ship',
    method: 'post',
    data: data
  });
}
function refundOrder(data) {
  return (0, _request.default)({
    url: '/order/refund',
    method: 'post',
    data: data
  });
}
function deleteOrder(data) {
  return (0, _request.default)({
    url: '/order/delete',
    method: 'post',
    data: data
  });
}
function replyComment(data) {
  return (0, _request.default)({
    url: '/order/reply',
    method: 'post',
    data: data
  });
}
function listChannel(id) {
  return (0, _request.default)({
    url: '/order/channel',
    method: 'get'
  });
}
function listShipGoods(data) {
  return (0, _request.default)({
    url: '/order/ship/goods/list',
    method: 'post',
    data: data
  });
}
function updateOrderPrice(data) {
  return (0, _request.default)({
    url: '/order/price/update',
    method: 'post',
    data: data
  });
}
function updateOrderAddress(data) {
  return (0, _request.default)({
    url: '/order/address/update',
    method: 'post',
    data: data
  });
}