"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
var _coupon = require("@/api/coupon");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// Secondary package based on el-pagination

var defaultTypeOptions = [{
  label: '通用领券',
  value: 0
}, {
  label: '注册赠券',
  value: 1
}, {
  label: '兑换码',
  value: 2
}];
var defaultStatusOptions = [{
  label: '正常',
  value: 0
}, {
  label: '已过期',
  value: 1
}, {
  label: '已下架',
  value: 2
}];
var _default = exports.default = {
  name: 'ProCoupon',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    formatType: function formatType(type) {
      for (var i = 0; i < defaultTypeOptions.length; i++) {
        if (type === defaultTypeOptions[i].value) {
          return defaultTypeOptions[i].label;
        }
      }
      return '';
    },
    formatGoodsType: function formatGoodsType(goodsType) {
      if (goodsType === 0) {
        return '全场通用';
      } else if (goodsType === 1) {
        return '指定分类';
      } else {
        return '指定商品';
      }
    },
    formatStatus: function formatStatus(status) {
      if (status === 0) {
        return '正常';
      } else if (status === 1) {
        return '已过期';
      } else {
        return '已下架';
      }
    }
  },
  data: function data() {
    return {
      typeOptions: Object.assign({}, defaultTypeOptions),
      statusOptions: Object.assign({}, defaultStatusOptions),
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        name: undefined,
        type: undefined,
        status: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      dataForm: {
        id: undefined,
        name: undefined,
        desc: undefined,
        tag: undefined,
        total: 0,
        discount: 0,
        min: 0,
        limit: 1,
        type: 0,
        status: 0,
        goodsType: 0,
        goodsValue: [],
        timeType: 0,
        days: 0,
        startTime: null,
        endTime: null
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        name: [{
          required: true,
          message: '优惠券标题不能为空',
          trigger: 'blur'
        }]
      },
      downloadLoading: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _coupon.listCoupon)(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.dataForm = {
        id: undefined,
        name: undefined,
        desc: undefined,
        tag: undefined,
        total: 0,
        discount: 0,
        min: 0,
        limit: 1,
        type: 0,
        status: 0,
        goodsType: 0,
        goodsValue: [],
        timeType: 0,
        days: 0,
        startTime: null,
        endTime: null
      };
    },
    handleCreate: function handleCreate() {
      var _this2 = this;
      this.resetForm();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this3 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _coupon.createCoupon)(_this3.dataForm).then(function (response) {
            _this3.list.unshift(response.data.data);
            _this3.dialogFormVisible = false;
            _this3.$notify.success({
              title: '成功',
              message: '创建优惠券成功'
            });
          }).catch(function (response) {
            _this3.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;
      this.dataForm = Object.assign({}, row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        if (_this4.dataForm.days === 0) {
          _this4.dataForm.daysType = 1;
        } else {
          _this4.dataForm.daysType = 0;
        }
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this5 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _coupon.updateCoupon)(_this5.dataForm).then(function () {
            var _iterator = (0, _createForOfIteratorHelper2.default)(_this5.list),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var v = _step.value;
                if (v.id === _this5.dataForm.id) {
                  var index = _this5.list.indexOf(v);
                  _this5.list.splice(index, 1, _this5.dataForm);
                  break;
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
            _this5.dialogFormVisible = false;
            _this5.$notify.success({
              title: '成功',
              message: '更新优惠券成功'
            });
          }).catch(function (response) {
            _this5.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this6 = this;
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _coupon.deleteCoupon)(row).then(function (response) {
          _this6.$notify.success({
            title: '成功',
            message: '删除优惠券成功'
          });
          var index = _this6.list.indexOf(row);
          _this6.list.splice(index, 1);
        }).catch(function (response) {
          _this6.$notify.error({
            title: '失败',
            message: response.data.errmsg
          });
        });
      });
    },
    handleDetail: function handleDetail(row) {
      this.$router.push({
        path: '/promotion/couponDetail',
        query: {
          id: row.id
        }
      });
    },
    handleDownload: function handleDownload() {
      var _this7 = this;
      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['优惠券ID', '名称', '内容', '标签', '最低消费', '减免金额', '每人限领', '优惠券数量'];
        var filterVal = ['id', 'name', 'desc', 'tag', 'min', 'discount', 'limit', 'total'];
        excel.export_json_to_excel2(tHeader, _this7.list, filterVal, '优惠券信息');
        _this7.downloadLoading = false;
      });
    }
  }
};