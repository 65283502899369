"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _login = require("@/api/login");
var _auth = require("@/utils/auth");
/**
* 用户设置
*/
var user = {
  state: {
    user: '',
    status: '',
    code: '',
    token: (0, _auth.getToken)(),
    name: '',
    avatar: '',
    introduction: '',
    roles: [],
    perms: [],
    setting: {
      articlePlatform: []
    }
  },
  mutations: {
    SET_CODE: function SET_CODE(state, code) {
      state.code = code;
    },
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_INTRODUCTION: function SET_INTRODUCTION(state, introduction) {
      state.introduction = introduction;
    },
    SET_SETTING: function SET_SETTING(state, setting) {
      state.setting = setting;
    },
    SET_STATUS: function SET_STATUS(state, status) {
      state.status = status;
    },
    SET_NAME: function SET_NAME(state, name) {
      state.name = name;
    },
    SET_AVATAR: function SET_AVATAR(state, avatar) {
      state.avatar = avatar;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_PERMS: function SET_PERMS(state, perms) {
      state.perms = perms;
    }
  },
  actions: {
    // 用户名登录
    LoginByUsername: function LoginByUsername(_ref, userInfo) {
      var commit = _ref.commit;
      var username = userInfo.username.trim();
      return new Promise(function (resolve, reject) {
        (0, _login.loginByUsername)(username, userInfo.password).then(function (response) {
          var token = response.data.data.token;
          commit('SET_TOKEN', token);
          (0, _auth.setToken)(token);
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetUserInfo: function GetUserInfo(_ref2) {
      var commit = _ref2.commit,
        state = _ref2.state;
      return new Promise(function (resolve, reject) {
        (0, _login.getUserInfo)(state.token).then(function (response) {
          var data = response.data.data;
          if (data.perms && data.perms.length > 0) {
            // 验证返回的perms是否是一个非空数组
            commit('SET_PERMS', data.perms);
          } else {
            reject('getInfo: perms must be a non-null array !');
          }
          commit('SET_ROLES', data.roles);
          commit('SET_NAME', data.name);
          commit('SET_AVATAR', data.avatar);
          commit('SET_INTRODUCTION', data.introduction);
          resolve(response);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 第三方验证登录
    // LoginByThirdparty({ commit, state }, code) {
    //   return new Promise((resolve, reject) => {
    //     commit('SET_CODE', code)
    //     loginByThirdparty(state.status, state.email, state.code).then(response => {
    //       commit('SET_TOKEN', response.data.token)
    //       setToken(response.data.token)
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    // 登出
    LogOut: function LogOut(_ref3) {
      var commit = _ref3.commit,
        state = _ref3.state;
      return new Promise(function (resolve, reject) {
        (0, _login.logout)(state.token).then(function () {
          commit('SET_TOKEN', '');
          commit('SET_ROLES', []);
          commit('SET_PERMS', []);
          (0, _auth.removeToken)();
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 前端 登出
    FedLogOut: function FedLogOut(_ref4) {
      var commit = _ref4.commit;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', '');
        (0, _auth.removeToken)();
        resolve();
      });
    },
    // 动态修改权限
    ChangeRoles: function ChangeRoles(_ref5, role) {
      var commit = _ref5.commit,
        dispatch = _ref5.dispatch;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', role);
        (0, _auth.setToken)(role);
        (0, _login.getUserInfo)(role).then(function (response) {
          var data = response.data;
          commit('SET_ROLES', data.roles);
          commit('SET_PERMS', data.perms);
          commit('SET_NAME', data.name);
          commit('SET_AVATAR', data.avatar);
          commit('SET_INTRODUCTION', data.introduction);
          dispatch('GenerateRoutes', data); // 动态修改权限后 重绘侧边菜单
          resolve();
        });
      });
    }
  }
};
var _default = exports.default = user;