var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["POST /admin/spec/createSpecMultiLang"],
                  expression: "['POST /admin/spec/createSpecMultiLang']",
                },
              ],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.handleCreate },
            },
            [_vm._v("添加")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "100px",
              label: "规格ID",
              prop: "id",
              sortable: "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "规格名称", prop: "name" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "规格值", prop: "inputList" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.inputList.join(",")) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "排序", prop: "sort" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作时间", prop: "updateTime" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              width: "250",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["POST /admin/spec/update"],
                            expression: "['POST /admin/spec/update']",
                          },
                        ],
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["POST /admin/spec/delete"],
                            expression: "['POST /admin/spec/delete']",
                          },
                        ],
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { margin: "0px 50px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.dataForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "400px" },
                  attrs: { label: "规格名称", prop: "name" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入规格名称" },
                    model: {
                      value: _vm.dataForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "name", $$v)
                      },
                      expression: "dataForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "400px" },
                  attrs: { label: "排序", prop: "sort" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "number", placeholder: "请输入排序" },
                    model: {
                      value: _vm.dataForm.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "sort", $$v)
                      },
                      expression: "dataForm.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "规格值" } },
                [
                  _c("star-input-tag", {
                    attrs: { theme: "+ NewTag" },
                    model: {
                      value: _vm.tagList,
                      callback: function ($$v) {
                        _vm.tagList = $$v
                      },
                      expression: "tagList",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _vm.dialogStatus === "create"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.createData },
                    },
                    [_vm._v("确定")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.updateData },
                    },
                    [_vm._v("确定")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }