"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listStyleSkuInventory = listStyleSkuInventory;
var _requestTwo = _interopRequireDefault(require("@/utils/requestTwo"));
function listStyleSkuInventory(data) {
  return (0, _requestTwo.default)({
    url: '/scm/app/inventory/listStyleSkuInventory',
    method: 'post',
    data: data
  });
}