"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
var _admin = require("@/api/admin");
var _role = require("@/api/role");
var _storage = require("@/api/storage");
var _auth = require("@/utils/auth");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Secondary package based on el-pagination
var _default = exports.default = {
  name: 'SysAdmin',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      uploadPath: _storage.uploadPath,
      list: null,
      total: 0,
      roleOptions: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        username: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      dataForm: {
        id: undefined,
        username: undefined,
        password: undefined,
        avatar: undefined,
        roleIds: []
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        username: [{
          required: true,
          message: '管理员名称不能为空',
          trigger: 'blur'
        }, {
          min: 6,
          message: '管理员名称长度至少6个字符',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '密码不能为空',
          trigger: 'blur'
        }, {
          min: 6,
          message: '密码长度至少6个字符',
          trigger: 'blur'
        }]
      },
      downloadLoading: false
    };
  },
  computed: {
    headers: function headers() {
      return {
        'Authorization': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    var _this = this;
    this.getList();
    (0, _role.roleOptions)().then(function (response) {
      _this.roleOptions = response.data.data.list;
    });
  },
  methods: {
    formatRole: function formatRole(roleId) {
      for (var i = 0; i < this.roleOptions.length; i++) {
        if (roleId === this.roleOptions[i].value) {
          return this.roleOptions[i].label;
        }
      }
      return '';
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _admin.listAdmin)(this.listQuery).then(function (response) {
        _this2.list = response.data.data.list;
        _this2.total = response.data.data.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.list = [];
        _this2.total = 0;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.dataForm = {
        id: undefined,
        username: undefined,
        password: undefined,
        avatar: undefined,
        roleIds: []
      };
    },
    uploadAvatar: function uploadAvatar(response) {
      this.dataForm.avatar = response.data.url;
    },
    handleCreate: function handleCreate() {
      var _this3 = this;
      this.resetForm();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this3.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this4 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _admin.createAdmin)(_this4.dataForm).then(function (response) {
            _this4.list.unshift(response.data.data);
            _this4.dialogFormVisible = false;
            _this4.$notify.success({
              title: '成功',
              message: '添加管理员成功'
            });
          }).catch(function (response) {
            _this4.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this5 = this;
      this.dataForm = Object.assign({}, row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this5.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this6 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _admin.updateAdmin)(_this6.dataForm).then(function () {
            var _iterator = (0, _createForOfIteratorHelper2.default)(_this6.list),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var v = _step.value;
                if (v.id === _this6.dataForm.id) {
                  var index = _this6.list.indexOf(v);
                  _this6.list.splice(index, 1, _this6.dataForm);
                  break;
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
            _this6.dialogFormVisible = false;
            _this6.$notify.success({
              title: '成功',
              message: '更新管理员成功'
            });
          }).catch(function (response) {
            _this6.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this7 = this;
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _admin.deleteAdmin)(row).then(function (response) {
          _this7.$notify.success({
            title: '成功',
            message: '删除管理员成功'
          });
          var index = _this7.list.indexOf(row);
          _this7.list.splice(index, 1);
        }).catch(function (response) {
          _this7.$notify.error({
            title: '失败',
            message: response.data.errmsg
          });
        });
      });
    },
    handleDownload: function handleDownload() {
      var _this8 = this;
      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['管理员ID', '管理员名称', '管理员头像'];
        var filterVal = ['id', 'username', 'avatar'];
        excel.export_json_to_excel2(tHeader, _this8.list, filterVal, '管理员信息');
        _this8.downloadLoading = false;
      });
    }
  }
};