var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { clearable: "", placeholder: "请输入售后编号" },
            model: {
              value: _vm.listQuery.aftersaleSn,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "aftersaleSn", $$v)
              },
              expression: "listQuery.aftersaleSn",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { clearable: "", placeholder: "请输入订单编号" },
            model: {
              value: _vm.listQuery.orderSn,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "orderSn", $$v)
              },
              expression: "listQuery.orderSn",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["GET /admin/aftersale/list"],
                  expression: "['GET /admin/aftersale/list']",
                },
              ],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v("查找")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: {
                loading: _vm.downloadLoading,
                type: "primary",
                icon: "el-icon-download",
              },
              on: { click: _vm.handleDownload },
            },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "全部", name: "0" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "待处理", name: "1" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "已完成", name: "2" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "已拒绝", name: "3" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "售后编号", prop: "aftersaleSn" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "商品名称",
              prop: "orderGoodsName",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "售后类型", prop: "type" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      { attrs: { type: _vm.typeTag[scope.row.type] } },
                      [_vm._v(_vm._s(_vm.typeDesc[scope.row.type]))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "申请时间", prop: "addTime" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "用户昵称", prop: "nickname" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "售后状态", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-tag", [
                      _vm._v(
                        _vm._s(_vm._f("orderStatusFilter")(scope.row.status))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "商品金额", prop: "goodsAmount" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "退款金额", prop: "amount" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "订单编号", prop: "orderSn" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              "min-width": "120",
              "class-name": "small-padding",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status === 101 && scope.row.type != 0
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["POST /admin/aftersale/detail"],
                                expression: "['POST /admin/aftersale/detail']",
                              },
                            ],
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDetail(scope.row, true)
                              },
                            },
                          },
                          [_vm._v("处理售后")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 101 && scope.row.type == 0
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["POST /admin/aftersale/detail"],
                                expression: "['POST /admin/aftersale/detail']",
                              },
                            ],
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDetail(scope.row, true)
                              },
                            },
                          },
                          [_vm._v("处理退款")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 102 ||
                    scope.row.status === 201 ||
                    scope.row.status === 202 ||
                    scope.row.status === 301
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["POST /admin/aftersale/detail"],
                                expression: "['POST /admin/aftersale/detail']",
                              },
                            ],
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDetail(scope.row, false)
                              },
                            },
                          },
                          [_vm._v("查看详情")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 203
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["POST /admin/aftersale/refund"],
                                expression: "['POST /admin/aftersale/refund']",
                              },
                            ],
                            attrs: { type: "warning", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleRefund(scope.row)
                              },
                            },
                          },
                          [_vm._v("确认收货")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-tooltip",
        { attrs: { placement: "top", content: "返回顶部" } },
        [_c("back-to-top", { attrs: { "visibility-height": 100 } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }