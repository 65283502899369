"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createRole = createRole;
exports.deleteRole = deleteRole;
exports.getPermission = getPermission;
exports.listRole = listRole;
exports.readRole = readRole;
exports.roleOptions = roleOptions;
exports.updatePermission = updatePermission;
exports.updateRole = updateRole;
var _request = _interopRequireDefault(require("@/utils/request"));
function listRole(query) {
  return (0, _request.default)({
    url: '/role/list',
    method: 'get',
    params: query
  });
}
function createRole(data) {
  return (0, _request.default)({
    url: '/role/create',
    method: 'post',
    data: data
  });
}
function readRole(data) {
  return (0, _request.default)({
    url: '/role/read',
    method: 'get',
    data: data
  });
}
function updateRole(data) {
  return (0, _request.default)({
    url: '/role/update',
    method: 'post',
    data: data
  });
}
function deleteRole(data) {
  return (0, _request.default)({
    url: '/role/delete',
    method: 'post',
    data: data
  });
}
function getPermission(query) {
  return (0, _request.default)({
    url: '/role/permissions',
    method: 'get',
    params: query
  });
}
function updatePermission(data) {
  return (0, _request.default)({
    url: '/role/permissions',
    method: 'post',
    data: data
  });
}
function roleOptions(query) {
  return (0, _request.default)({
    url: '/role/options',
    method: 'get',
    params: query
  });
}