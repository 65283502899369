var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.dynamicTags, function (tag, index) {
        return _c(
          "el-tag",
          {
            key: index,
            attrs: { closable: "", "disable-transitions": false },
            on: {
              click: function ($event) {
                return _vm.editTag(tag, index)
              },
              close: function ($event) {
                return _vm.handleClose(tag)
              },
            },
          },
          [
            index != _vm.num ? _c("span", [_vm._v(_vm._s(tag))]) : _vm._e(),
            _vm._v(" "),
            index == _vm.num
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.words,
                      expression: "words",
                    },
                  ],
                  ref: "editInput",
                  refInFor: true,
                  staticClass: "custom_input",
                  attrs: { type: "text" },
                  domProps: { value: _vm.words },
                  on: {
                    blur: function ($event) {
                      return _vm.handleInput(tag, index)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.words = $event.target.value
                    },
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleInput(tag, index)
                    },
                  },
                })
              : _vm._e(),
          ]
        )
      }),
      _vm._v(" "),
      _vm.inputVisible
        ? _c("el-input", {
            ref: "saveTagInput",
            staticClass: "input-new-tag",
            attrs: { size: "small" },
            on: { blur: _vm.handleInputConfirm },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleInputConfirm($event)
              },
            },
            model: {
              value: _vm.inputValue,
              callback: function ($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue",
            },
          })
        : _c(
            "el-button",
            {
              staticClass: "button-new-tag",
              attrs: { size: "small" },
              on: { click: _vm.showInput },
            },
            [_vm._v(_vm._s(_vm.theme))]
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }