var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["POST /admin/category/create"],
                  expression: "['POST /admin/category/create']",
                },
              ],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.handleCreate },
            },
            [_vm._v("添加")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": "",
            "row-key": "id",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "类目ID", prop: "id" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "类目名", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.multiLanguageDisplay(scope.row.name)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", property: "iconUrl", label: "类目图标" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.iconUrl
                      ? _c("img", {
                          attrs: { src: scope.row.iconUrl, width: "40" },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", property: "picUrl", label: "类目图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.picUrl
                      ? _c("img", {
                          attrs: { src: scope.row.picUrl, width: "80" },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "关键字", prop: "keywords" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.multiLanguageDisplay(scope.row.keywords)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100",
              label: "简介",
              prop: "desc",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.multiLanguageDisplay(scope.row.desc)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "级别", prop: "level" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type:
                            scope.row.level === "L1"
                              ? "primary"
                              : scope.row.level === "L2"
                              ? "info"
                              : "success",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            scope.row.level === "L1"
                              ? "一级类目"
                              : scope.row.level === "L2"
                              ? "二级类目"
                              : "三级类目"
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              width: "250",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["POST /admin/category/update"],
                            expression: "['POST /admin/category/update']",
                          },
                        ],
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["POST /admin/category/delete"],
                            expression: "['POST /admin/category/delete']",
                          },
                        ],
                        staticClass: "btn",
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑模板")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["POST /admin/category/delete"],
                            expression: "['POST /admin/category/delete']",
                          },
                        ],
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.dataForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类目名称", prop: "name" } },
                [
                  _c("MultiLanguageInp", {
                    attrs: {
                      "inp-data-j-s-o-n": _vm.dataForm.name,
                      "rules-inp": [_vm.required],
                    },
                    on: {
                      updateInp: function (val) {
                        return (_vm.dataForm.name = val)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "关键字", prop: "keywords" } },
                [
                  _c("MultiLanguageInp", {
                    attrs: { "inp-data-j-s-o-n": _vm.dataForm.keywords },
                    on: {
                      updateInp: function (val) {
                        return (_vm.dataForm.keywords = val)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "编码", prop: "code" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入款号编码" },
                    model: {
                      value: _vm.dataForm.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "code", $$v)
                      },
                      expression: "dataForm.code",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "级别", prop: "level" } },
                [
                  _c(
                    "el-select",
                    {
                      on: { change: _vm.onLevelChange },
                      model: {
                        value: _vm.dataForm.level,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "level", $$v)
                        },
                        expression: "dataForm.level",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "一级类目", value: "L1" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "二级类目", value: "L2" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "三级类目", value: "L3" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.dataForm.level === "L2"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "父类目", prop: "pidL2" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.dataForm.pidL2,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "pidL2", $$v)
                            },
                            expression: "dataForm.pidL2",
                          },
                        },
                        _vm._l(_vm.catL1, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: {
                              label: _vm.multiLanguageDisplay(item.label),
                              value: item.value,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dataForm.level === "L3"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "父类目", prop: "pidL3" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.dataForm.pidL3,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "pidL3", $$v)
                            },
                            expression: "dataForm.pidL3",
                          },
                        },
                        _vm._l(_vm.catL2, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: {
                              label: _vm.multiLanguageDisplay(item.label),
                              value: item.value,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类目图标", prop: "iconUrl" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        headers: _vm.headers,
                        action: _vm.uploadPath,
                        "show-file-list": false,
                        "on-success": _vm.uploadIconUrl,
                        accept: ".jpg,.jpeg,.png,.gif",
                      },
                    },
                    [
                      _vm.dataForm.iconUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.dataForm.iconUrl },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类目图片", prop: "picUrl" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        headers: _vm.headers,
                        action: _vm.uploadPath,
                        "show-file-list": false,
                        "on-success": _vm.uploadPicUrl,
                        accept: ".jpg,.jpeg,.png,.gif",
                      },
                    },
                    [
                      _vm.dataForm.picUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.dataForm.picUrl },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类目简介", prop: "desc" } },
                [
                  _c("MultiLanguageInp", {
                    attrs: { "inp-data-j-s-o-n": _vm.dataForm.desc },
                    on: {
                      updateInp: function (val) {
                        return (_vm.dataForm.desc = val)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _vm.dialogStatus == "create"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.createData },
                    },
                    [_vm._v("确定")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.updateData },
                    },
                    [_vm._v("确定")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑模板",
            visible: _vm.editDialogVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDialogVisible = $event
            },
          },
        },
        [
          _vm._l(_vm.templateList, function (item, index) {
            return _c(
              "div",
              { key: item.id, staticClass: "template" },
              [
                _c("MultiLanguageInp", {
                  staticStyle: { width: "500px", "margin-right": "20px" },
                  attrs: { "inp-data-j-s-o-n": item.name },
                  on: {
                    updateInp: function (val) {
                      return (item.name = val)
                    },
                  },
                }),
                _vm._v(" "),
                _vm.templateList.length !== 1
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDeleteTemplate(index)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%" },
              attrs: { type: "primary" },
              on: { click: _vm.handleAddTemplate },
            },
            [_vm._v("增加")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editDialogVisible = false
                      _vm.templateList = []
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }