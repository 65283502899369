"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'EditTextTemp',
  props: ['detail'],
  data: function data() {
    return {
      ismouseDown: false,
      clientX: 0,
      clientY: 0
    };
  },
  mounted: function mounted() {
    var that = this;
    document.body.addEventListener('mouseup', function (ev) {
      ev.stopPropagation();
      that.ismouseDown = false;
    });
  },
  methods: {
    // 既改变宽度也改变高度
    resizeBox: function resizeBox(ev, x, y) {
      if (this.ismouseDown) {
        this.detail.EditWidth = this.detail.EditWidth + (ev.clientX - this.clientX) * x;
        this.detail.EditHeight = this.detail.EditHeight + (ev.clientY - this.clientY) * y;
        if (x < 0) {
          this.detail.EditLeft = this.detail.EditLeft - (ev.clientX - this.clientX) * x;
        }
        if (y < 0) {
          this.detail.EditTop = this.detail.EditTop - (ev.clientY - this.clientY) * y;
        }
        this.clientX = ev.clientX;
        this.clientY = ev.clientY;
      }
    },
    mousedownPoint: function mousedownPoint(ev) {
      ev.stopPropagation();
      this.ismouseDown = true;
      this.clientX = ev.clientX;
      this.clientY = ev.clientY;
      console.log(this.detail);
    }
  }
};