"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.multiLanguageDisplay = exports.checkFileType = void 0;
var multiLanguageDisplay = exports.multiLanguageDisplay = function multiLanguageDisplay(string) {
  if (!string) {
    return '';
  }
  var lang = localStorage.getItem('lang') ? localStorage.getItem('lang').substring(0, 2) : 'zh';
  console.log(lang, 'lang');
  var displayString = '';
  if (isJSON(string)) {
    for (var item in JSON.parse(string)) {
      if (item.substring(0, 2) === lang) {
        displayString = JSON.parse(string)[item];
      }
    }
  } else {
    displayString = string;
  }
  return displayString;
};
var isJSON = function isJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
var checkFileType = exports.checkFileType = function checkFileType(url) {
  // 获取链接中最后一个点的位置
  var lastDotIndex = url.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    // 获取后缀名
    var extension = url.substring(lastDotIndex + 1).toLowerCase();

    // 判断后缀名
    if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {
      return '视频';
    } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
      return '图片';
    } else if (extension === 'js') {
      return 'JavaScript文件';
    } else {
      return '其他文件类型';
    }
  }

  // 如果没有找到点，则无法确定文件类型
  return '未知文件类型';
};