"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'TextTemplate',
  props: ['detail'],
  data: function data() {
    return {
      isEditText: false,
      isDragDown: false,
      textClientX: 0,
      textClientY: 0
    };
  },
  mounted: function mounted() {
    var that = this;
    document.body.addEventListener('mousedown', function (ev) {
      var i = ev.target;
      var iparents = [];
      while (i.tagName !== 'BODY') {
        iparents.push(i);
        i = i.parentNode;
      }
      if (iparents.filter(function (element) {
        return element.className === 'text_template';
      }).length === 0) {
        that.isEditText = false;
      }
    });
    document.body.addEventListener('mouseup', function (ev) {
      ev.stopPropagation();
      that.isDragDown = false;
    });
  },
  methods: {
    changeEditStatus: function changeEditStatus(ev) {
      this.isEditText = true;
    },
    dragTextDown: function dragTextDown(ev) {
      this.isDragDown = true;
      this.textClientX = ev.clientX;
      this.textClientY = ev.clientY;
    },
    dragTextMove: function dragTextMove(ev) {
      if (this.isDragDown) {
        this.detail.left = this.detail.left + (ev.clientX - this.textClientX);
        this.detail.top = this.detail.top + (ev.clientY - this.textClientY);
        this.textClientX = ev.clientX;
        this.textClientY = ev.clientY;
      }
    }
  }
};