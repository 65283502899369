var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "edit_pic_temp",
      style: {
        width: _vm.picSize.width + "px",
        height: _vm.picSize.height + "px",
      },
    },
    [
      _c("div", {
        staticClass: "edit_point",
        staticStyle: { left: "-4px", top: "-4px", cursor: "nw-resize" },
        on: {
          mousedown: _vm.mousedownPoint,
          mousemove: function ($event) {
            return _vm.resizeBox($event, -1, -1)
          },
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "edit_point rightTop",
        staticStyle: { right: "-4px", top: "-4px", cursor: "ne-resize" },
        on: {
          mousedown: _vm.mousedownPoint,
          mousemove: function ($event) {
            return _vm.resizeBox($event, 1, -1)
          },
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "edit_point",
        staticStyle: { left: "-4px", bottom: "-4px", cursor: "sw-resize" },
        on: {
          mousedown: _vm.mousedownPoint,
          mousemove: function ($event) {
            return _vm.resizeBox($event, -1, 1)
          },
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "edit_point",
        staticStyle: { right: "-4px", bottom: "-4px", cursor: "se-resize" },
        on: {
          mousedown: _vm.mousedownPoint,
          mousemove: function ($event) {
            return _vm.resizeBox($event, 1, 1)
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }