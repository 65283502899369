"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _aftersale = require("@/api/aftersale");
var _BackToTop = _interopRequireDefault(require("@/components/BackToTop"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// Secondary package based on el-pagination
// import _ from 'lodash'

var typesMap = {
  0: '退款',
  1: '退款退货',
  2: '换货'
};
var statusMap = {
  101: '售后中',
  102: '已取消',
  201: '已完成',
  202: '已拒绝',
  203: '退货中',
  301: '已退款'
};
var _default = exports.default = {
  name: 'MallAftersale',
  components: {
    BackToTop: _BackToTop.default,
    Pagination: _Pagination.default
  },
  filters: {
    afterTypesFilter: function afterTypesFilter(type) {
      return typesMap[type];
    },
    orderStatusFilter: function orderStatusFilter(status) {
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      list: [],
      total: 0,
      listLoading: true,
      tab: '0',
      listQuery: {
        page: 1,
        limit: 20,
        aftersaleSn: undefined,
        orderSn: undefined,
        showType: '0',
        sort: 'add_time',
        order: 'desc'
      },
      typeTag: ['', 'success', 'warning'],
      typeDesc: ['退款', '退款退货', '换货'],
      multipleSelection: [],
      contentDetail: '',
      contentDialogVisible: false,
      downloadLoading: false
    };
  },
  created: function created() {
    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
      this.listQuery.showType = this.$route.query.tab;
    }
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _aftersale.listAftersale)(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleClick: function handleClick() {
      this.listQuery.showType = this.tab;
      this.getList();
    },
    // 详情
    handleDetail: function handleDetail(row, show) {
      this.$router.push({
        path: '/mall/aftersaledetail',
        query: {
          id: row.id
        }
      });
    },
    // 退款
    handleRefund: function handleRefund(row) {
      var _this2 = this;
      this.$confirm('是否要进行确认收货操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _aftersale.refundAftersale)(row).then(function (response) {
          _this2.$notify.success({
            title: '成功',
            message: '退款操作成功'
          });
          _this2.getList();
        }).catch(function (response) {
          _this2.$notify.error({
            title: '失败',
            message: response.data.errmsg
          });
        });
      });
    },
    // 导出
    handleDownload: function handleDownload() {
      var _this3 = this;
      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['售后编号', '订单ID', '用户ID', '售后类型', '退款原因', '退款价格', '申请时间'];
        var filterVal = ['aftersaleSn', 'orderId', 'userId', 'type', 'reason', 'amount', 'addTime'];
        excel.export_json_to_excel2(tHeader, _this3.list, filterVal, '售后信息');
        _this3.downloadLoading = false;
      });
    }
  }
};