"use strict";

tinymce.addI18n('zh_CN', {
  "Cut": "\u526A\u5207",
  "Heading 5": "\u6807\u98985",
  "Header 2": "\u6807\u98982",
  "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X\/C\/V keyboard shortcuts instead.": "\u4F60\u7684\u6D4F\u89C8\u5668\u4E0D\u652F\u6301\u5BF9\u526A\u8D34\u677F\u7684\u8BBF\u95EE\uFF0C\u8BF7\u4F7F\u7528Ctrl+X/C/V\u952E\u8FDB\u884C\u590D\u5236\u7C98\u8D34\u3002",
  "Heading 4": "\u6807\u98984",
  "Div": "Div\u533A\u5757",
  "Heading 2": "\u6807\u98982",
  "Paste": "\u7C98\u8D34",
  "Close": "\u5173\u95ED",
  "Font Family": "\u5B57\u4F53",
  "Pre": "\u9884\u683C\u5F0F\u6587\u672C",
  "Align right": "\u53F3\u5BF9\u9F50",
  "New document": "\u65B0\u6587\u6863",
  "Blockquote": "\u5F15\u7528",
  "Numbered list": "\u7F16\u53F7\u5217\u8868",
  "Heading 1": "\u6807\u98981",
  "Headings": "\u6807\u9898",
  "Increase indent": "\u589E\u52A0\u7F29\u8FDB",
  "Formats": "\u683C\u5F0F",
  "Headers": "\u6807\u9898",
  "Select all": "\u5168\u9009",
  "Header 3": "\u6807\u98983",
  "Blocks": "\u533A\u5757",
  "Undo": "\u64A4\u6D88",
  "Strikethrough": "\u5220\u9664\u7EBF",
  "Bullet list": "\u9879\u76EE\u7B26\u53F7",
  "Header 1": "\u6807\u98981",
  "Superscript": "\u4E0A\u6807",
  "Clear formatting": "\u6E05\u9664\u683C\u5F0F",
  "Font Sizes": "\u5B57\u53F7",
  "Subscript": "\u4E0B\u6807",
  "Header 6": "\u6807\u98986",
  "Redo": "\u91CD\u590D",
  "Paragraph": "\u6BB5\u843D",
  "Ok": "\u786E\u5B9A",
  "Bold": "\u7C97\u4F53",
  "Code": "\u4EE3\u7801",
  "Italic": "\u659C\u4F53",
  "Align center": "\u5C45\u4E2D",
  "Header 5": "\u6807\u98985",
  "Heading 6": "\u6807\u98986",
  "Heading 3": "\u6807\u98983",
  "Decrease indent": "\u51CF\u5C11\u7F29\u8FDB",
  "Header 4": "\u6807\u98984",
  "Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.": "\u5F53\u524D\u4E3A\u7EAF\u6587\u672C\u7C98\u8D34\u6A21\u5F0F\uFF0C\u518D\u6B21\u70B9\u51FB\u53EF\u4EE5\u56DE\u5230\u666E\u901A\u7C98\u8D34\u6A21\u5F0F\u3002",
  "Underline": "\u4E0B\u5212\u7EBF",
  "Cancel": "\u53D6\u6D88",
  "Justify": "\u4E24\u7AEF\u5BF9\u9F50",
  "Inline": "\u6587\u672C",
  "Copy": "\u590D\u5236",
  "Align left": "\u5DE6\u5BF9\u9F50",
  "Visual aids": "\u7F51\u683C\u7EBF",
  "Lower Greek": "\u5C0F\u5199\u5E0C\u814A\u5B57\u6BCD",
  "Square": "\u65B9\u5757",
  "Default": "\u9ED8\u8BA4",
  "Lower Alpha": "\u5C0F\u5199\u82F1\u6587\u5B57\u6BCD",
  "Circle": "\u7A7A\u5FC3\u5706",
  "Disc": "\u5B9E\u5FC3\u5706",
  "Upper Alpha": "\u5927\u5199\u82F1\u6587\u5B57\u6BCD",
  "Upper Roman": "\u5927\u5199\u7F57\u9A6C\u5B57\u6BCD",
  "Lower Roman": "\u5C0F\u5199\u7F57\u9A6C\u5B57\u6BCD",
  "Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.": "\u6807\u8BC6\u7B26\u5E94\u8BE5\u4EE5\u5B57\u6BCD\u5F00\u5934\uFF0C\u540E\u8DDF\u5B57\u6BCD\u3001\u6570\u5B57\u3001\u7834\u6298\u53F7\u3001\u70B9\u3001\u5192\u53F7\u6216\u4E0B\u5212\u7EBF\u3002",
  "Name": "\u540D\u79F0",
  "Anchor": "\u951A\u70B9",
  "Id": "\u6807\u8BC6\u7B26",
  "You have unsaved changes are you sure you want to navigate away?": "\u4F60\u8FD8\u6709\u6587\u6863\u5C1A\u672A\u4FDD\u5B58\uFF0C\u786E\u5B9A\u8981\u79BB\u5F00\uFF1F",
  "Restore last draft": "\u6062\u590D\u4E0A\u6B21\u7684\u8349\u7A3F",
  "Special character": "\u7279\u6B8A\u7B26\u53F7",
  "Source code": "\u6E90\u4EE3\u7801",
  "Language": "\u8BED\u8A00",
  "Insert\/Edit code sample": "\u63D2\u5165/\u7F16\u8F91\u4EE3\u7801\u793A\u4F8B",
  "B": "B",
  "R": "R",
  "G": "G",
  "Color": "\u989C\u8272",
  "Right to left": "\u4ECE\u53F3\u5230\u5DE6",
  "Left to right": "\u4ECE\u5DE6\u5230\u53F3",
  "Emoticons": "\u8868\u60C5",
  "Robots": "\u673A\u5668\u4EBA",
  "Document properties": "\u6587\u6863\u5C5E\u6027",
  "Title": "\u6807\u9898",
  "Keywords": "\u5173\u952E\u8BCD",
  "Encoding": "\u7F16\u7801",
  "Description": "\u63CF\u8FF0",
  "Author": "\u4F5C\u8005",
  "Fullscreen": "\u5168\u5C4F",
  "Horizontal line": "\u6C34\u5E73\u5206\u5272\u7EBF",
  "Horizontal space": "\u6C34\u5E73\u8FB9\u8DDD",
  "Insert\/edit image": "\u63D2\u5165/\u7F16\u8F91\u56FE\u7247",
  "General": "\u666E\u901A",
  "Advanced": "\u9AD8\u7EA7",
  "Source": "\u5730\u5740",
  "Border": "\u8FB9\u6846",
  "Constrain proportions": "\u4FDD\u6301\u7EB5\u6A2A\u6BD4",
  "Vertical space": "\u5782\u76F4\u8FB9\u8DDD",
  "Image description": "\u56FE\u7247\u63CF\u8FF0",
  "Style": "\u6837\u5F0F",
  "Dimensions": "\u5927\u5C0F",
  "Insert image": "\u63D2\u5165\u56FE\u7247",
  "Image": "\u56FE\u7247",
  "Zoom in": "\u653E\u5927",
  "Contrast": "\u5BF9\u6BD4\u5EA6",
  "Back": "\u540E\u9000",
  "Gamma": "\u4F3D\u9A6C\u503C",
  "Flip horizontally": "\u6C34\u5E73\u7FFB\u8F6C",
  "Resize": "\u8C03\u6574\u5927\u5C0F",
  "Sharpen": "\u9510\u5316",
  "Zoom out": "\u7F29\u5C0F",
  "Image options": "\u56FE\u7247\u9009\u9879",
  "Apply": "\u5E94\u7528",
  "Brightness": "\u4EAE\u5EA6",
  "Rotate clockwise": "\u987A\u65F6\u9488\u65CB\u8F6C",
  "Rotate counterclockwise": "\u9006\u65F6\u9488\u65CB\u8F6C",
  "Edit image": "\u7F16\u8F91\u56FE\u7247",
  "Color levels": "\u989C\u8272\u5C42\u6B21",
  "Crop": "\u88C1\u526A",
  "Orientation": "\u65B9\u5411",
  "Flip vertically": "\u5782\u76F4\u7FFB\u8F6C",
  "Invert": "\u53CD\u8F6C",
  "Date\/time": "\u65E5\u671F/\u65F6\u95F4",
  "Insert date\/time": "\u63D2\u5165\u65E5\u671F/\u65F6\u95F4",
  "Remove link": "\u5220\u9664\u94FE\u63A5",
  "Url": "\u5730\u5740",
  "Text to display": "\u663E\u793A\u6587\u5B57",
  "Anchors": "\u951A\u70B9",
  "Insert link": "\u63D2\u5165\u94FE\u63A5",
  "Link": "\u94FE\u63A5",
  "New window": "\u5728\u65B0\u7A97\u53E3\u6253\u5F00",
  "None": "\u65E0",
  "The URL you entered seems to be an external link. Do you want to add the required http:\/\/ prefix?": "\u4F60\u6240\u586B\u5199\u7684URL\u5730\u5740\u5C5E\u4E8E\u5916\u90E8\u94FE\u63A5\uFF0C\u9700\u8981\u52A0\u4E0Ahttp://:\u524D\u7F00\u5417\uFF1F",
  "Paste or type a link": "\u7C98\u8D34\u6216\u8F93\u5165\u94FE\u63A5",
  "Target": "\u6253\u5F00\u65B9\u5F0F",
  "The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?": "\u4F60\u6240\u586B\u5199\u7684URL\u5730\u5740\u4E3A\u90AE\u4EF6\u5730\u5740\uFF0C\u9700\u8981\u52A0\u4E0Amailto:\u524D\u7F00\u5417\uFF1F",
  "Insert\/edit link": "\u63D2\u5165/\u7F16\u8F91\u94FE\u63A5",
  "Insert\/edit video": "\u63D2\u5165/\u7F16\u8F91\u89C6\u9891",
  "Media": "\u5A92\u4F53",
  "Alternative source": "\u955C\u50CF",
  "Paste your embed code below:": "\u5C06\u5185\u5D4C\u4EE3\u7801\u7C98\u8D34\u5728\u4E0B\u9762:",
  "Insert video": "\u63D2\u5165\u89C6\u9891",
  "Poster": "\u5C01\u9762",
  "Insert\/edit media": "\u63D2\u5165/\u7F16\u8F91\u5A92\u4F53",
  "Embed": "\u5185\u5D4C",
  "Nonbreaking space": "\u4E0D\u95F4\u65AD\u7A7A\u683C",
  "Page break": "\u5206\u9875\u7B26",
  "Paste as text": "\u7C98\u8D34\u4E3A\u6587\u672C",
  "Preview": "\u9884\u89C8",
  "Print": "\u6253\u5370",
  "Save": "\u4FDD\u5B58",
  "Could not find the specified string.": "\u672A\u627E\u5230\u641C\u7D22\u5185\u5BB9.",
  "Replace": "\u66FF\u6362",
  "Next": "\u4E0B\u4E00\u4E2A",
  "Whole words": "\u5168\u5B57\u5339\u914D",
  "Find and replace": "\u67E5\u627E\u548C\u66FF\u6362",
  "Replace with": "\u66FF\u6362\u4E3A",
  "Find": "\u67E5\u627E",
  "Replace all": "\u5168\u90E8\u66FF\u6362",
  "Match case": "\u533A\u5206\u5927\u5C0F\u5199",
  "Prev": "\u4E0A\u4E00\u4E2A",
  "Spellcheck": "\u62FC\u5199\u68C0\u67E5",
  "Finish": "\u5B8C\u6210",
  "Ignore all": "\u5168\u90E8\u5FFD\u7565",
  "Ignore": "\u5FFD\u7565",
  "Add to Dictionary": "\u6DFB\u52A0\u5230\u5B57\u5178",
  "Insert row before": "\u5728\u4E0A\u65B9\u63D2\u5165",
  "Rows": "\u884C",
  "Height": "\u9AD8",
  "Paste row after": "\u7C98\u8D34\u5230\u4E0B\u65B9",
  "Alignment": "\u5BF9\u9F50\u65B9\u5F0F",
  "Border color": "\u8FB9\u6846\u989C\u8272",
  "Column group": "\u5217\u7EC4",
  "Row": "\u884C",
  "Insert column before": "\u5728\u5DE6\u4FA7\u63D2\u5165",
  "Split cell": "\u62C6\u5206\u5355\u5143\u683C",
  "Cell padding": "\u5355\u5143\u683C\u5185\u8FB9\u8DDD",
  "Cell spacing": "\u5355\u5143\u683C\u5916\u95F4\u8DDD",
  "Row type": "\u884C\u7C7B\u578B",
  "Insert table": "\u63D2\u5165\u8868\u683C",
  "Body": "\u8868\u4F53",
  "Caption": "\u6807\u9898",
  "Footer": "\u8868\u5C3E",
  "Delete row": "\u5220\u9664\u884C",
  "Paste row before": "\u7C98\u8D34\u5230\u4E0A\u65B9",
  "Scope": "\u8303\u56F4",
  "Delete table": "\u5220\u9664\u8868\u683C",
  "H Align": "\u6C34\u5E73\u5BF9\u9F50",
  "Top": "\u9876\u90E8\u5BF9\u9F50",
  "Header cell": "\u8868\u5934\u5355\u5143\u683C",
  "Column": "\u5217",
  "Row group": "\u884C\u7EC4",
  "Cell": "\u5355\u5143\u683C",
  "Middle": "\u5782\u76F4\u5C45\u4E2D",
  "Cell type": "\u5355\u5143\u683C\u7C7B\u578B",
  "Copy row": "\u590D\u5236\u884C",
  "Row properties": "\u884C\u5C5E\u6027",
  "Table properties": "\u8868\u683C\u5C5E\u6027",
  "Bottom": "\u5E95\u90E8\u5BF9\u9F50",
  "V Align": "\u5782\u76F4\u5BF9\u9F50",
  "Header": "\u8868\u5934",
  "Right": "\u53F3\u5BF9\u9F50",
  "Insert column after": "\u5728\u53F3\u4FA7\u63D2\u5165",
  "Cols": "\u5217",
  "Insert row after": "\u5728\u4E0B\u65B9\u63D2\u5165",
  "Width": "\u5BBD",
  "Cell properties": "\u5355\u5143\u683C\u5C5E\u6027",
  "Left": "\u5DE6\u5BF9\u9F50",
  "Cut row": "\u526A\u5207\u884C",
  "Delete column": "\u5220\u9664\u5217",
  "Center": "\u5C45\u4E2D",
  "Merge cells": "\u5408\u5E76\u5355\u5143\u683C",
  "Insert template": "\u63D2\u5165\u6A21\u677F",
  "Templates": "\u6A21\u677F",
  "Background color": "\u80CC\u666F\u8272",
  "Custom...": "\u81EA\u5B9A\u4E49...",
  "Custom color": "\u81EA\u5B9A\u4E49\u989C\u8272",
  "No color": "\u65E0",
  "Text color": "\u6587\u5B57\u989C\u8272",
  "Table of Contents": "\u5185\u5BB9\u5217\u8868",
  "Show blocks": "\u663E\u793A\u533A\u5757\u8FB9\u6846",
  "Show invisible characters": "\u663E\u793A\u4E0D\u53EF\u89C1\u5B57\u7B26",
  "Words: {0}": "\u5B57\u6570\uFF1A{0}",
  "Insert": "\u63D2\u5165",
  "File": "\u6587\u4EF6",
  "Edit": "\u7F16\u8F91",
  "Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help": "\u5728\u7F16\u8F91\u533A\u6309ALT-F9\u6253\u5F00\u83DC\u5355\uFF0C\u6309ALT-F10\u6253\u5F00\u5DE5\u5177\u680F\uFF0C\u6309ALT-0\u67E5\u770B\u5E2E\u52A9",
  "Tools": "\u5DE5\u5177",
  "View": "\u89C6\u56FE",
  "Table": "\u8868\u683C",
  "Format": "\u683C\u5F0F"
});