var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "100px",
              label: "用户ID",
              prop: "userId",
              sortable: "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", property: "avatar", label: "头像" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.avatar
                      ? _c("img", {
                          attrs: { src: scope.row.avatar, width: "80" },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "用户昵称", prop: "name" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "手机号码", prop: "phone" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "邮箱", prop: "email" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "公司", prop: "company" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "地址", prop: "address" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作时间", prop: "updateTime" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }