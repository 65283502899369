var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.goodsDItem.type == "video"
    ? _c(
        "div",
        {
          staticClass: "previewsellItem",
          staticStyle: {
            position: "relative",
            overflow: "hidden",
            height: "422px",
          },
          style: { backgroundColor: _vm.goodsDItem.backgroundColor },
        },
        [
          _c(
            "video",
            {
              staticStyle: { width: "100%" },
              attrs: { controls: "controls", src: _vm.goodsDItem.url },
            },
            [_vm._v("\n    你的浏览器不支持视频播放\n  ")]
          ),
        ]
      )
    : _c(
        "div",
        {
          staticClass: "previewsellItem",
          staticStyle: { position: "relative", overflow: "hidden" },
          style: {
            backgroundColor: _vm.goodsDItem.backgroundColor,
            height: _vm.goodsDItem.height + "px",
          },
        },
        _vm._l(_vm.goodsDItem.children, function (detail, index) {
          return _c(
            "div",
            { key: index, staticClass: "previewsellDItem" },
            [
              detail.type == "pic"
                ? [
                    _c("img", {
                      staticStyle: { position: "absolute" },
                      style: {
                        width: detail.width + "px",
                        height: detail.height + "px",
                        top: detail.top + "px",
                        left: detail.left + "px",
                      },
                      attrs: { src: detail.url },
                    }),
                  ]
                : [
                    _c("div", {
                      staticStyle: { position: "absolute" },
                      style: {
                        width: detail.width + "px",
                        height: detail.height + "px",
                        top: detail.top + "px",
                        left: detail.left + "px",
                        color: detail.color,
                        backgroundColor: detail.bgColor,
                        fontWeight: detail.fontWeight,
                        fontStyle: detail.fontStyle,
                        fontSize: detail.fontSize + "px",
                      },
                      domProps: { innerHTML: _vm._s(_vm.content[index]) },
                    }),
                  ],
            ],
            2
          )
        }),
        0
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }