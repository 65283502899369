"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _storage = require("@/api/storage");
var _textBase = _interopRequireDefault(require("@/components/BaseTemplate/textBase"));
var _picBase = _interopRequireDefault(require("@/components/BaseTemplate/picBase"));
var _videoBase = _interopRequireDefault(require("@/components/BaseTemplate/videoBase"));
var _editTextTemp = _interopRequireDefault(require("@/components/BaseTemplate/components/editTextTemp"));
var _textEditTool = _interopRequireDefault(require("@/components/BaseTemplate/components/textEditTool"));
var _pic = _interopRequireDefault(require("@/assets/image/pic.jpg"));
var _video = _interopRequireDefault(require("@/assets/image/video.png"));
require("@/assets/iconfont/font_gd/iconfont.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'TemplateEdit',
  components: {
    TextBase: _textBase.default,
    PicBase: _picBase.default,
    VideoBase: _videoBase.default,
    EditTextTemp: _editTextTemp.default,
    TextEditTool: _textEditTool.default
  },
  props: ['goodsDetailList', 'headers'],
  data: function data() {
    return {
      uploadPath: _storage.uploadPath,
      isShow: false,
      gdLActive: 0,
      ismouseDown: false,
      picImgUrl: _pic.default,
      videoImgUrl: _video.default,
      EditText: {
        target: '',
        EditWidth: 0,
        EditHeight: 0,
        EditTop: 0,
        EditLeft: 0,
        EditFontWeight: 400,
        EditFontStyle: 'normal',
        EditFontSize: 0,
        EditColor: '#333',
        EditBgColor: 'transparent'
      },
      isEditText: false
    };
  },
  computed: {
    getTarget: function getTarget() {
      return this.EditText.target;
    },
    getWidth: function getWidth() {
      return this.EditText.EditWidth;
    },
    getHeight: function getHeight() {
      return this.EditText.EditHeight;
    },
    getTop: function getTop() {
      return this.EditText.EditTop;
    },
    getLeft: function getLeft() {
      return this.EditText.EditLeft;
    },
    getFontWeight: function getFontWeight() {
      return this.EditText.EditFontWeight;
    },
    getFontStyle: function getFontStyle() {
      return this.EditText.EditFontStyle;
    },
    getFontSize: function getFontSize() {
      return this.EditText.EditFontSize;
    },
    getColor: function getColor() {
      return this.EditText.EditColor;
    },
    getBgColor: function getBgColor() {
      return this.EditText.EditBgColor;
    }
  },
  watch: {
    // 监听编辑对象的改变
    getTarget: function getTarget(newTarget, oldTarget) {
      // console.log("编辑对象改变", this.EditText);
      if (newTarget === '') return;
      var index = Array.prototype.indexOf.call(newTarget.parentNode.children, newTarget);
      this.changeEditDetail(index);
    },
    // 监听子模板宽度变化
    getWidth: function getWidth(newData, oldData) {
      if (this.EditText.target === '') return;
      var index = Array.prototype.indexOf.call(this.EditText.target.parentNode.children, this.EditText.target);
      this.changeTempDetail(index);
    },
    // 监听子模板高度变化
    getHeight: function getHeight(newData, oldData) {
      if (this.EditText.target === '') return;
      var index = Array.prototype.indexOf.call(this.EditText.target.parentNode.children, this.EditText.target);
      this.changeTempDetail(index);
    },
    // 监听子模板上偏移量变化
    getTop: function getTop(newData, oldData) {
      if (this.EditText.target === '') return;
      var index = Array.prototype.indexOf.call(this.EditText.target.parentNode.children, this.EditText.target);
      this.changeTempDetail(index);
    },
    // 监听子模板左偏移量变化
    getLeft: function getLeft(newData, oldData) {
      if (this.EditText.target === '') return;
      var index = Array.prototype.indexOf.call(this.EditText.target.parentNode.children, this.EditText.target);
      this.changeTempDetail(index);
    },
    // 监听文字粗体的改变
    getFontWeight: function getFontWeight(newFont, oldFont) {
      if (this.EditText.target === '') return;
      var index = Array.prototype.indexOf.call(this.EditText.target.parentNode.children, this.EditText.target);
      this.changeTempDetail(index);
    },
    // 监听文字斜体的改变
    getFontStyle: function getFontStyle(newFont, oldFont) {
      if (this.EditText.target === '') return;
      var index = Array.prototype.indexOf.call(this.EditText.target.parentNode.children, this.EditText.target);
      this.changeTempDetail(index);
    },
    // 监听文字字号的改变
    getFontSize: function getFontSize(newFont, oldFont) {
      if (this.EditText.target === '') return;
      var index = Array.prototype.indexOf.call(this.EditText.target.parentNode.children, this.EditText.target);
      this.changeTempDetail(index);
    },
    // 监听文字颜色的改变
    getColor: function getColor(newFont, oldFont) {
      if (this.EditText.target === '') return;
      var index = Array.prototype.indexOf.call(this.EditText.target.parentNode.children, this.EditText.target);
      this.changeTempDetail(index);
    },
    // 监听文字背景颜色的改变
    getBgColor: function getBgColor(newFont, oldFont) {
      if (this.EditText.target === '') return;
      var index = Array.prototype.indexOf.call(this.EditText.target.parentNode.children, this.EditText.target);
      this.changeTempDetail(index);
    }
  },
  mounted: function mounted() {
    var that = this;
    document.body.addEventListener('mousedown', function (ev) {
      ev.stopPropagation();
      // console.log("点击" + ev.target.tagName);
      var x = ev.target;
      var parents = [];
      while (x.tagName !== 'BODY') {
        parents.push(x);
        x = x.parentNode;
      }
      if (parents.filter(function (element) {
        return element.className === 'TextEditBox';
      }).length || ev.target.tagName === 'TEXTAREA' || parents.filter(function (element) {
        return element.className === 'el-color-dropdown el-color-picker__panel';
      }).length || ev.target.className === 'edit_text_temp') {
        that.isEditText = true;
      } else {
        that.isEditText = false;
      }
      if (ev.target.tagName === 'TEXTAREA') {
        var idx = Array.prototype.indexOf.call(ev.target.parentElement.parentElement.parentElement.parentElement.parentNode.children, ev.target.parentElement.parentElement.parentElement.parentElement);
        that.gdLActive = idx;
        that.EditText.target = ev.target.parentElement;
      }
      if (parents.filter(function (element) {
        return element.className === 'sellItem';
      }).length === 0 && parents.filter(function (element) {
        return element.className === 'el-button createBaseTemp el-button--default el-button--medium';
      }).length === 0 && parents.filter(function (element) {
        return element.className === 'el-button el-button--default el-button--small el-button--primary ';
      }).length === 0) {
        that.gdLActive = null;
      }
    });
    document.body.addEventListener('mouseup', function (ev) {
      ev.stopPropagation();
      that.ismouseDown = false;
      that.clientY = 0;
    });
  },
  methods: {
    // 获得模板高度
    getTempHeight: function getTempHeight() {
      var H = 0;
      this.goodsDetailList.forEach(function (element) {
        H = H + element.height;
      });
      this.sellHeight = H;
      this.$emit('getSellH', H);
      if (H > 730) {
        document.querySelector('.sell').style.height = H + 'px';
      } else {
        document.querySelector('.sell').style.height = '730px';
      }
    },
    // 构建文本标题模板
    createTextTitleTemp: function createTextTitleTemp() {
      this.gdLActive = this.goodsDetailList.length;
      this.goodsDetailList.push({
        type: 'textTitle',
        height: 152,
        backgroundColor: '#fff',
        children: [{
          type: 'text',
          width: 205,
          height: 57,
          top: 31,
          left: 273,
          fontWeight: 400,
          fontStyle: 'normal',
          fontSize: 40,
          color: '#333',
          bgColor: 'transparent',
          content: '请输入标题'
        }, {
          type: 'text',
          width: 124,
          height: 45,
          top: 86,
          left: 314,
          fontWeight: 400,
          fontStyle: 'normal',
          fontSize: 20,
          color: '#9d9d9d',
          bgColor: 'transparent',
          content: 'INPUT TITLE'
        }]
      });
      this.isShow = false;
      this.getTempHeight();
      this.EditObjInit();
    },
    // 构建文本内容模板
    createTextContentTemp: function createTextContentTemp() {
      this.gdLActive = this.goodsDetailList.length;
      this.goodsDetailList.push({
        type: 'textContent',
        height: 138,
        backgroundColor: '#fff',
        children: [{
          type: 'text',
          width: 558,
          height: 73,
          top: 36,
          left: 104,
          fontWeight: 400,
          fontStyle: 'normal',
          fontSize: 20,
          color: '#333',
          bgColor: 'transparent',
          content: '请输入内容请输入内容请输入内容请输入内容请输入内容请输入内容请输入内容请输入内容请输入内容请输入内容'
        }]
      });
      this.isShow = false;
      this.getTempHeight();
      this.EditObjInit();
    },
    // 构建图片模板
    createPicTemp: function createPicTemp() {
      this.isShow = false;
    },
    picSuccess: function picSuccess(res) {
      this.gdLActive = this.goodsDetailList.length;
      var img_url = res.data.url; // 图片URL地址
      var imgObj = new Image(); // 创建对象
      imgObj.src = img_url; // 改变图片地址
      var that = this;
      imgObj.onload = function () {
        that.goodsDetailList.push({
          type: 'pic',
          height: imgObj.height / imgObj.width * 750,
          backgroundColor: '#fff',
          children: [{
            type: 'pic',
            url: img_url,
            top: 0,
            left: 0,
            width: 750,
            height: imgObj.height / imgObj.width * 750
          }]
        });
        that.getTempHeight();
      };
    },
    beforePicUpload: function beforePicUpload(file, fileList) {
      return this.$confirm("\u786E\u5B9A\u4E0A\u4F20 ".concat(file.name, "\uFF1F"));
    },
    // 构建视频模板
    createVideoTemp: function createVideoTemp() {
      this.gdLActive = this.goodsDetailList.length;
      this.isShow = false;
    },
    videoSuccess: function videoSuccess(res) {
      this.loading.close();
      this.$message.success('视频上传成功');
      this.goodsDetailList.push({
        type: 'video',
        height: 422,
        backgroundColor: '#fff',
        url: res.data.url
      });
      this.getTempHeight();
    },
    videoError: function videoError() {
      this.loading.close();
      this.$message.error('视频上传失败');
    },
    videoProgress: function videoProgress() {
      this.loading = this.$loading({
        lock: true,
        text: '视频上传中，请耐心等待',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.4)'
      });
    },
    beforeVideoUpload: function beforeVideoUpload(file, fileList) {
      var isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('上传的视频大小不能超过 10MB!');
        return false;
      } else {
        return this.$confirm("\u786E\u5B9A\u4E0A\u4F20 ".concat(file.name, "\uFF1F"));
      }
    },
    judgeIndex: function judgeIndex(ev, index) {
      var y = ev.target;
      var yparents = [];
      while (y.tagName !== 'BODY') {
        yparents.push(y);
        y = y.parentNode;
      }
      if (yparents.filter(function (element) {
        return element.className === 'pic_template';
      }).length) {
        this.isEditText = false;
      }
      this.gdLActive = index;
      if (ev.target.tagName === 'TEXTAREA') {
        var idx = Array.prototype.indexOf.call(ev.target.parentElement.parentNode.children, ev.target.parentElement);
        this.changeEditDetail(idx);
      }
    },
    // 插入图片
    innerPicSuccess: function innerPicSuccess(res) {
      var img_url = res.data.url; // 图片URL地址
      var imgObj = new Image(); // 创建对象
      imgObj.src = img_url; // 改变图片地址
      var that = this;
      imgObj.onload = function () {
        var imgWidth = imgObj.width > 750 ? 750 : imgObj.width;
        var imgLeft = (750 - imgWidth) / 2;
        that.goodsDetailList[that.gdLActive].children.push({
          type: 'pic',
          url: img_url,
          top: 0,
          left: imgLeft,
          width: imgWidth,
          height: imgObj.height / imgObj.width * imgWidth
        });
        that.getTempHeight();
      };
    },
    // 插入文本框
    insertChildrenText: function insertChildrenText(ev) {
      ev.stopPropagation();
      this.goodsDetailList[this.gdLActive].children.push({
        type: 'text',
        width: 200,
        height: 50,
        top: 100,
        left: 100,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 20,
        color: '#333',
        bgColor: 'transparent',
        content: '请在此输入文字'
      });
    },
    // 上移
    upSellItem: function upSellItem(ev) {
      ev.stopPropagation();
      if (this.goodsDetailList.length === 1) return;
      if (this.gdLActive) {
        this.gdLActive = this.gdLActive - 1;
      }
      this.goodsDetailList[this.gdLActive] = [this.goodsDetailList[this.gdLActive + 1], this.goodsDetailList[this.gdLActive + 1] = this.goodsDetailList[this.gdLActive]][0];
    },
    // 下移
    downSellItem: function downSellItem(ev) {
      ev.stopPropagation();
      if (this.goodsDetailList.length === 1) return;
      if (this.gdLActive < this.goodsDetailList.length - 1) {
        this.gdLActive = this.gdLActive + 1;
      }
      this.goodsDetailList[this.gdLActive] = [this.goodsDetailList[this.gdLActive - 1], this.goodsDetailList[this.gdLActive - 1] = this.goodsDetailList[this.gdLActive]][0];
    },
    // 删除
    deleteSellItem: function deleteSellItem(ev) {
      ev.stopPropagation();
      this.goodsDetailList.splice(this.gdLActive, 1);
      this.gdLActive = null;
      this.getTempHeight();
    },
    // 改变模板高度
    mousedownResizeBtn: function mousedownResizeBtn(ev) {
      ev.stopPropagation();
      this.ismouseDown = true;
      this.clientY = ev.clientY;
    },
    resizeSell: function resizeSell(ev, y) {
      ev.stopPropagation();
      if (this.ismouseDown) {
        this.goodsDetailList[this.gdLActive].height = this.goodsDetailList[this.gdLActive].height + (ev.clientY - this.clientY) * y;
        this.clientY = ev.clientY;
        this.getTempHeight();
      }
    },
    // 改变正在编辑的属性
    changeEditDetail: function changeEditDetail(idx) {
      this.EditText.EditWidth = this.goodsDetailList[this.gdLActive].children[idx].width;
      this.EditText.EditHeight = this.goodsDetailList[this.gdLActive].children[idx].height;
      this.EditText.EditTop = this.goodsDetailList[this.gdLActive].children[idx].top;
      this.EditText.EditLeft = this.goodsDetailList[this.gdLActive].children[idx].left;
      this.EditText.EditFontWeight = this.goodsDetailList[this.gdLActive].children[idx].fontWeight;
      this.EditText.EditFontStyle = this.goodsDetailList[this.gdLActive].children[idx].fontStyle;
      this.EditText.EditFontSize = this.goodsDetailList[this.gdLActive].children[idx].fontSize;
      this.EditText.EditColor = this.goodsDetailList[this.gdLActive].children[idx].color;
      this.EditText.EditBgColor = this.goodsDetailList[this.gdLActive].children[idx].bgColor;
    },
    // 改变模板元素的属性
    changeTempDetail: function changeTempDetail(idx) {
      this.goodsDetailList[this.gdLActive].children[idx].width = this.EditText.EditWidth;
      this.goodsDetailList[this.gdLActive].children[idx].height = this.EditText.EditHeight;
      this.goodsDetailList[this.gdLActive].children[idx].top = this.EditText.EditTop;
      this.goodsDetailList[this.gdLActive].children[idx].left = this.EditText.EditLeft;
      this.goodsDetailList[this.gdLActive].children[idx].fontWeight = this.EditText.EditFontWeight;
      this.goodsDetailList[this.gdLActive].children[idx].fontStyle = this.EditText.EditFontStyle;
      this.goodsDetailList[this.gdLActive].children[idx].fontSize = this.EditText.EditFontSize;
      this.goodsDetailList[this.gdLActive].children[idx].color = this.EditText.EditColor;
      this.goodsDetailList[this.gdLActive].children[idx].bgColor = this.EditText.EditBgColor;
    },
    // 格式化编辑的对象
    EditObjInit: function EditObjInit() {
      this.EditText = {
        target: '',
        EditWidth: 0,
        EditHeight: 0,
        EditTop: 0,
        EditLeft: 0,
        EditFontWeight: 400,
        EditFontStyle: 'normal',
        EditFontSize: 0,
        EditColor: '#333',
        EditBgColor: 'transparent'
      };
    }
  }
};