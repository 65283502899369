"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
require("core-js/modules/web.dom.iterable");
var _goods = require("@/api/goods");
var _BackToTop = _interopRequireDefault(require("@/components/BackToTop"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Secondary package based on el-pagination
var _default = exports.default = {
  name: 'GoodsList',
  components: {
    BackToTop: _BackToTop.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      url: process.env.VUE_APP_API_URL,
      list: [],
      checkAll: [],
      //选中的列表
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        goodsSn: undefined,
        name: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      goodsDetail: '',
      detailDialogVisible: false,
      downloadLoading: false,
      // 商品库存
      commonStock: '',
      deteledProducts: [],
      products: [],
      stockDialogVisible: false,
      // 商品库存日志
      logListLoading: false,
      logList: [],
      logTotal: 0,
      logListQuery: {
        goodsId: 0,
        page: 1,
        limit: 20,
        sort: 'id',
        order: 'desc'
      },
      // 修改商品售价
      priceForm: {
        id: null,
        counterPrice: 0,
        currency: 'USD'
      },
      unitOptions: ['USD', 'CNY'],
      priceRules: {
        counterPrice: [{
          required: true,
          message: '市场售价不能为空',
          trigger: 'blur'
        }]
      },
      priceDialogVisible: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 批量打印
    print: function print() {
      if (this.checkAll.length == 0) return this.$message.error('至少勾选一款进行打印');
      var goodsIdList = this.checkAll.map(function (item) {
        return item.id;
      });
      try {
        // printUrl({ goodsIds:goodsIdList.join(',') }).then(res => {
        //   let printData = [res];
        //   let pdfUrl = window.URL.createObjectURL(
        //     new Blob(printData, { type: "application/pdf" })
        //   );
        //   window.open(pdfUrl);
        // })
        var _printUrl = this.url + '/wx/goods/getQrCodePdf?goodsIds=' + goodsIdList.join(',');
        window.open(_printUrl);
      } catch (err) {
        this.$message.error('PDF加载失败');
      }
    },
    // 获取选中商品
    selctAll: function selctAll(val) {
      this.checkAll = val;
    },
    // 获取商品列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _goods.listGoods)(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    // 展示商品详情
    showDetail: function showDetail(detail) {
      this.goodsDetail = detail;
      this.detailDialogVisible = true;
    },
    // 商品库存：显示商品库存弹窗
    showStock: function showStock(id) {
      var _this2 = this;
      this.commonStock = '';
      this.deteledProducts = [];
      (0, _goods.detailGoods)(id).then(function (response) {
        _this2.products = response.data.data.products;
      });
      this.logListQuery.goodsId = id;
      this.getLogList();
      this.stockDialogVisible = true;
    },
    // 商品库存：同步库存
    handleSynStock: function handleSynStock() {
      var self = this;
      this.products.forEach(function (item, index, array) {
        item.number = self.commonStock;
      });
    },
    // 商品库存：删除某库存
    handleDeleteStock: function handleDeleteStock(row) {
      var index = this.products.indexOf(row);
      this.products.splice(index, 1);
      this.deteledProducts.push(row);
    },
    // 商品库存：保存
    handleUpdateStock: function handleUpdateStock() {
      var _this3 = this;
      if (this.products.length === 0) {
        this.$message.error('商品库存不能为空');
        return false;
      }
      var productvar = [];
      this.products.forEach(function (item, index, array) {
        productvar.push({
          id: item.id,
          storeCount: item.number,
          deleted: item.deleted
        });
      });
      this.deteledProducts.forEach(function (item, index, array) {
        productvar.push({
          id: item.id,
          storeCount: item.number,
          deleted: true
        });
      });
      (0, _goods.editProducts)({
        productList: productvar
      }).then(function (response) {
        _this3.$notify.success({
          title: '成功',
          message: '操作成功'
        });
        _this3.stockDialogVisible = false;
      }).catch(function (response) {
        _this3.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    },
    // 获取库存操作日志
    getLogList: function getLogList() {
      var _this4 = this;
      this.logListLoading = true;
      (0, _goods.listStockLog)(this.logListQuery).then(function (response) {
        _this4.logList = response.data.data.list;
        _this4.logTotal = response.data.data.total;
        _this4.logListLoading = false;
      }).catch(function () {
        _this4.list = [];
        _this4.total = 0;
        _this4.logListLoading = false;
      });
    },
    // 修改商品售价:弹窗
    handleUpdateCounterPrice: function handleUpdateCounterPrice(row) {
      var _this5 = this;
      this.priceForm = Object.assign({}, row);
      this.priceDialogVisible = true;
      this.$nextTick(function () {
        _this5.$refs['priceForm'].clearValidate();
      });
    },
    // 修改商品售价
    updatePrice: function updatePrice() {
      var _this6 = this;
      this.$refs['priceForm'].validate(function (valid) {
        if (valid) {
          (0, _goods.editGoodsPrice)({
            goodsId: _this6.priceForm.id,
            counterPrice: _this6.priceForm.counterPrice,
            currency: _this6.priceForm.currency
          }).then(function () {
            _this6.getList();
            _this6.priceDialogVisible = false;
            _this6.$notify.success({
              title: '成功',
              message: '更新成功'
            });
          }).catch(function (response) {
            _this6.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    // 修改商品状态
    handleProductStateChange: function handleProductStateChange(row) {
      var _this7 = this;
      (0, _goods.editGoodsStatus)({
        goodsId: row.id,
        saleState: row.onSale,
        newState: row.new,
        hotState: row.hot
      }).then(function (response) {
        _this7.$notify.success({
          title: '成功',
          message: '操作成功'
        });
        _this7.stockDialogVisible = false;
      }).catch(function (response) {
        _this7.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    },
    // 查询
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    // 新增商品
    handleCreate: function handleCreate() {
      this.$router.push({
        path: '/goods/create'
      });
    },
    // 修改商品
    handleUpdate: function handleUpdate(row) {
      this.$router.push({
        path: '/goods/edit',
        query: {
          id: row.id
        }
      });
    },
    // 删除商品
    handleDelete: function handleDelete(row) {
      var _this8 = this;
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _goods.deleteGoods)(row).then(function (response) {
          _this8.$notify.success({
            title: '成功',
            message: '删除成功'
          });
          var index = _this8.list.indexOf(row);
          _this8.list.splice(index, 1);
        }).catch(function (response) {
          _this8.$notify.error({
            title: '失败',
            message: response.data.errmsg
          });
        });
      });
    },
    // 导出
    handleDownload: function handleDownload() {
      var _this9 = this;
      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['商品ID', '商品编号', '名称', '专柜价格', '当前价格', '是否新品', '是否热品', '是否在售', '首页主图', '宣传图片列表', '商品介绍', '详细介绍', '商品图片', '商品单位', '关键字', '类目ID', '品牌商ID'];
        var filterVal = ['id', 'goodsSn', 'name', 'counterPrice', 'retailPrice', 'new', 'hot', 'onSale', 'listPicUrl', 'gallery', 'brief', 'detail', 'picUrl', 'goodsUnit', 'keywords', 'categoryId', 'brandId'];
        excel.export_json_to_excel2(tHeader, _this9.list, filterVal, '商品信息');
        _this9.downloadLoading = false;
      });
    }
  }
};