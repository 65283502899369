"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _config = require("@/api/config");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ConfigOrder',
  data: function data() {
    return {
      dataForm: {
        litemall_order_unpaid: 0,
        litemall_order_unconfirm: 0,
        litemall_order_comment: 0,
        litemall_order_minordernum: 0,
        litemall_order_minordernum_enabled: true
      },
      rules: {
        litemall_order_unpaid: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        litemall_order_unconfirm: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        litemall_order_comment: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;
      (0, _config.listOrder)().then(function (response) {
        _this.dataForm = response.data.data;
      });
    },
    cancel: function cancel() {
      this.init();
    },
    update: function update() {
      var _this2 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (!valid) {
          return false;
        }
        _this2.doUpdate();
      });
    },
    blurInp: function blurInp() {
      if (!this.dataForm.litemall_order_minordernum && this.dataForm.litemall_order_minordernum !== 0) {
        this.dataForm.litemall_order_minordernum = 0;
      }
    },
    doUpdate: function doUpdate() {
      var _this3 = this;
      (0, _config.updateOrder)(this.dataForm).then(function (response) {
        _this3.$notify.success({
          title: '成功',
          message: '订单参数配置成功'
        });
      }).catch(function (response) {
        _this3.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    }
  }
};