"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'EditPicTemp',
  props: ['picSize', 'ratio'],
  data: function data() {
    return {
      ismouseDown: false,
      PicBoxClientX: 0,
      PicBoxClientY: 0
    };
  },
  mounted: function mounted() {
    var that = this;
    document.body.addEventListener('mouseup', function (ev) {
      ev.stopPropagation();
      that.ismouseDown = false;
    });
  },
  methods: {
    // 既改变宽度也改变高度
    resizeBox: function resizeBox(ev, x, y) {
      ev.stopPropagation();
      if (this.ismouseDown) {
        var W = this.picSize.width;
        var H = this.picSize.height;
        if (Math.abs((ev.clientX - this.PicBoxClientX) * x) / W > Math.abs((ev.clientY - this.PicBoxClientY) * y) / H) {
          this.picSize.width = W + (ev.clientX - this.PicBoxClientX) * x;
          this.picSize.height = this.picSize.width / this.ratio;
          if (x < 0) {
            this.picSize.left = this.picSize.left - (ev.clientX - this.PicBoxClientX) * x;
          }
          if (y < 0) {
            this.picSize.top = this.picSize.top - this.picSize.height + H;
          }
        } else {
          this.picSize.height = H + (ev.clientY - this.PicBoxClientY) * y;
          this.picSize.width = this.picSize.height * this.ratio;
          if (x < 0) {
            this.picSize.left = this.picSize.left - this.picSize.width + W;
          }
          if (y < 0) {
            this.picSize.top = this.picSize.top - (ev.clientY - this.PicBoxClientY) * y;
          }
        }
        this.PicBoxClientX = ev.clientX;
        this.PicBoxClientY = ev.clientY;
      }
    },
    mousedownPoint: function mousedownPoint(ev) {
      ev.stopPropagation();
      this.ismouseDown = true;
      this.PicBoxClientX = ev.clientX;
      this.PicBoxClientY = ev.clientY;
    }
  }
};