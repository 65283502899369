"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.split");
var _goods = require("@/api/goods");
var _storage = require("@/api/storage");
var _mall = require("@/api/mall");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _ImgDrag = _interopRequireDefault(require("@/components/ImgDrag"));
var _TemplateEdit = _interopRequireDefault(require("@/components/BaseTemplate/TemplateEdit"));
var _previewTemp = _interopRequireDefault(require("@/components/BaseTemplate/previewTemp"));
var _elementUi = require("element-ui");
var _auth = require("@/utils/auth");
var _multiLanguageDisplay = require("@/utils/multiLanguageDisplay");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'GoodsEdit',
  components: {
    Tinymce: _Tinymce.default,
    ImageDrag: _ImgDrag.default,
    TemplateEdit: _TemplateEdit.default,
    PreviewTemp: _previewTemp.default
  },
  data: function data() {
    var validateGoodsSn = function validateGoodsSn(rule, value, callback) {
      var myreg = /[^\a-\z\A-\Z0-\9_\- /\\]/g;
      if (value.length > 30) {
        callback(new Error('商品款号长度过长,最多输入30字符！'));
      } else if (myreg.test(value)) {
        callback(new Error('商品款号请输入数字、字母、部分符号：_ - \\ / '));
      } else {
        callback();
      }
    };
    var priceFactor = function priceFactor(rule, value, callback) {
      if (value !== '') {
        var data = value.toString().split('.');
        if (data.length === 2 && data[1].length > 2) {
          callback(new Error('价格最多保留2位小数!'));
        }
      } else {
        callback();
      }
    };
    return {
      multiLanguageDisplay: _multiLanguageDisplay.multiLanguageDisplay,
      // 拖拽图片列表
      file_list: [],
      // 图片上传路径
      uploadPath: _storage.uploadPath,
      // 关键词
      newKeywordVisible: false,
      newKeyword: '',
      keywords: [],
      // 商品分类、品牌
      categoryList: [],
      brandList: [],
      categoryIds: [],
      // 商品基本信息
      goods: {
        gallery: []
      },
      unitOptions: ['USD', 'CNY'],
      commonPrice: 0.0,
      // 商品多规格选择
      value: '',
      specList: [],
      selectedSpecList: [],
      // 商品规格
      oldSpecifications: '',
      newSpecifications: [],
      attrValVisiable: false,
      attrValForm: {
        id: 0,
        name: '',
        value: '',
        inputList: ''
      },
      // 商品库存
      productVisiable: false,
      productForm: {
        id: 0,
        specifications: [],
        price: 0.0,
        number: 0,
        url: ''
      },
      newProducts: [],
      oldProducts: '',
      commonStock: '',
      // 商品参数
      attributes: [],
      // 点击上架按钮加载
      loading: false,
      // 字段验证
      rules: {
        name: [{
          required: true,
          message: '商品名称不能为空',
          trigger: 'blur'
        }],
        goodsSn: [{
          required: true,
          message: '商品款号不能为空',
          trigger: 'blur'
        }, {
          validator: validateGoodsSn,
          trigger: 'blur'
        }],
        counterPrice: [{
          required: true,
          message: '市场售价不能为空',
          trigger: 'blur'
        }, {
          validator: priceFactor,
          trigger: 'blur'
        }],
        livePrice: [{
          validator: priceFactor,
          trigger: 'blur'
        }],
        picUrl: [{
          required: true,
          message: '商品图片不能为空',
          trigger: 'blur'
        }],
        gallery: [{
          required: true,
          message: '宣传画廊不能为空',
          trigger: 'blur'
        }],
        categoryId: [{
          required: true,
          message: '商品分类不能为空',
          trigger: 'blur'
        }],
        detail: [{
          required: true,
          message: '商品详情不能为空',
          trigger: 'blur'
        }]
      },
      detailVisible: false
    };
  },
  computed: {
    // 头部信息
    headers: function headers() {
      return {
        Authorization: (0, _auth.getToken)()
      };
    },
    // 商品参数列表：根据商品参数新增、修改、删除改变
    attributesData: function attributesData() {
      var attributesData = [];
      for (var i = 0; i < this.attributes.length; i++) {
        if (this.attributes[i].deleted) {
          continue;
        }
        attributesData.push(this.attributes[i]);
      }
      attributesData.forEach(function (item) {
        item.value = (0, _multiLanguageDisplay.multiLanguageDisplay)(item.value);
        item.attribute = (0, _multiLanguageDisplay.multiLanguageDisplay)(item.attribute);
      });
      return attributesData;
    }
  },
  created: function created() {
    this.init();
  },
  methods: {
    addLabelAttribute: function addLabelAttribute(arr) {
      var _this = this;
      arr.forEach(function (item) {
        item.label = _this.multiLanguageDisplay(item.label);
        if (item.children && item.children.length) {
          _this.addLabelAttribute(item.children);
        }
      });
    },
    // 数据初始化
    init: function init() {
      var _this2 = this;
      if (this.$route.query.id === null) {
        return;
      }
      var goodsId = this.$route.query.id;
      // 获取商品信息
      (0, _goods.detailGoods)(goodsId).then(function (response) {
        _this2.goods = response.data.data.goods;
        _this2.goods.name = (0, _multiLanguageDisplay.multiLanguageDisplay)(_this2.goods.name);
        // 稍微调整一下前后端不一致
        if (_this2.goods.brandId === 0) {
          _this2.goods.brandId = null;
        }
        if (_this2.goods.keywords === '') {
          _this2.goods.keywords = null;
        }
        _this2.oldSpecifications = JSON.stringify(response.data.data.specifications);
        _this2.newSpecifications = response.data.data.specifications;

        // this.newSpecifications.push({
        //         specification:"颜色",
        //         value:"蓝色",
        // })

        _this2.newProducts = response.data.data.products;
        _this2.oldProducts = JSON.stringify(response.data.data.products);
        _this2.attributes = response.data.data.attributes;
        _this2.categoryIds = response.data.data.categoryIds;
        _this2.file_list = [];
        for (var i = 0; i < _this2.goods.gallery.length; i++) {
          _this2.file_list.push(_this2.goods.gallery[i]);
        }
        var keywords = response.data.data.goods.keywords;
        if (keywords !== null) {
          _this2.keywords = keywords.split(',');
        }
        if (_this2.goods.detailTemplate) {
          _this2.goods.detailTemplate = JSON.parse(_this2.goods.detailTemplate);
        }
        console.log(_this2.goods);
        // 获取商品的规格和规格值
        _this2.specList = [];
        // 获取当前商品的规格和规格的规格值
        (0, _mall.listSpec)({
          page: 1,
          limit: 1000,
          sort: 'sort',
          order: 'desc'
        }).then(function (response2) {
          var self = _this2;
          var spec = [];
          self.newSpecifications.forEach(function (item, index, arr) {
            response2.data.data.list.forEach(function (val, index, arr) {
              if (item.specification === val.name) {
                var _index = spec.indexOf(item.specification);
                if (_index < 0) {
                  spec.push(item.specification);
                  self.selectedSpecList.push({
                    id: val.id,
                    name: val.name,
                    items: JSON.parse(val.inputList),
                    checkItem: []
                  });
                }
              }
            });
          });

          // 将当前商品的规格值已选中状态
          self.selectedSpecList.forEach(function (selectedItem, index, arr) {
            self.newSpecifications.forEach(function (item, index, arr) {
              if (item.specification === selectedItem.name) {
                var _index2 = selectedItem.items.indexOf(item.value);
                if (_index2 < 0) {
                  selectedItem.items.push(item.value);
                }
                selectedItem.checkItem.push(item.value);
              }
            });
          });
        }).catch(function (errmsg) {
          self.selectedSpecList = [];
        });
      });

      // 获取商品分类和品牌商
      (0, _goods.listCatAndBrand)().then(function (response) {
        _this2.categoryList = response.data.data.categoryList;
        _this2.addLabelAttribute(_this2.categoryList);
        _this2.brandList = response.data.data.brandList;
      });
    },
    // 市场售价监听改变
    counterPriceChange: function counterPriceChange() {
      var self = this;
      self.commonPrice = this.goods.counterPrice;
      for (var i = 0; i < this.newProducts.length; i++) {
        this.newProducts[i].price = self.commonPrice;
        this.newProducts[i].updateTime = '';
      }
    },
    // 商品分类
    handleCategoryChange: function handleCategoryChange(value) {
      this.goods.categoryId = value[value.length - 1];
    },
    // 关键词:删除关键词
    handleClose: function handleClose(tag) {
      this.keywords.splice(this.keywords.indexOf(tag), 1);
      this.goods.keywords = this.keywords.toString();
    },
    // 关键词:新增关键词Input框:Input获取焦点
    showInput: function showInput() {
      var _this3 = this;
      this.newKeywordVisible = true;
      this.$nextTick(function (_) {
        _this3.$refs.newKeywordInput.$refs.input.focus();
      });
    },
    // 关键词:新增关键词：Input失去焦点
    handleInputConfirm: function handleInputConfirm() {
      var newKeyword = this.newKeyword;
      if (newKeyword) {
        this.keywords.push(newKeyword);
        this.goods.keywords = this.keywords.toString();
      }
      this.newKeywordVisible = false;
      this.newKeyword = '';
    },
    // 商品图片：上传成功回调函数
    uploadPicUrl: function uploadPicUrl(response) {
      this.goods.picUrl = response.data.url;
    },
    // 画廊：图片拖拽排序、图片上传前格式、大小限制
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('图片大小不超过10M');
        return false;
      }
      return true;
    },
    // 画廊：图片上传成功
    bannerPicSuccess: function bannerPicSuccess(response, file, fileList) {
      if (response.errno === 0) {
        this.file_list.push(response.data.url);
      }
    },
    // 画廊：图片上传失败
    uploadError: function uploadError() {
      this.$message.error('上传失败，请重新上传');
    },
    // 画廊：拖拽保存图片
    updateFile: function updateFile(val) {
      this.file_list = val;
      console.log(this.file_list);
    },
    // 商品规格:确认选中的规格，生成商品库存列表
    handleSelectSpec: function handleSelectSpec() {
      // 同步库存判断：每个商品规格的值必需选中一个
      var name = [];
      this.selectedSpecList.forEach(function (val, index, arr) {
        val.checkItem.forEach(function (item, index, arr) {
          var indexe = name.indexOf(val.name);
          if (indexe < 0) {
            name.push(val.name);
          }
        });
      });
      if (name.length !== this.selectedSpecList.length) {
        this.$message.error('每个商品规格的值必需选中一个');
        return;
      }
      // 生成商品规格
      this.newSpecifications = [];
      var self = this;
      this.selectedSpecList.forEach(function (val, index, arr) {
        val.checkItem.forEach(function (item, index, arr) {
          var specForm = {
            specification: val.name,
            value: item,
            picUrl: '',
            addTime: '',
            deleted: false,
            goodsId: self.goods.id,
            id: 0,
            updateTime: ''
          };
          self.newSpecifications.push(specForm);
        });
      });
      this.specToProduct();
    },
    // 商品规格:新增商品规格值弹窗
    handleAddAttrVal: function handleAddAttrVal(item) {
      var inputList = item.items.join('，');
      this.attrValForm = {
        id: item.id,
        name: item.name,
        value: '',
        inputList: inputList
      };
      this.attrValVisiable = true;
    },
    // 商品库存：根据商品规则生成商品库存列表
    specToProduct: function specToProduct() {
      if (this.newSpecifications.length === 0) {
        this.newProducts = [];
        return;
      }
      // 根据specifications创建临时规格列表
      var specValues = [];
      var spec = this.newSpecifications[0].specification;
      var values = [];
      values.push(0);
      for (var i = 1; i < this.newSpecifications.length; i++) {
        var aspec = this.newSpecifications[i].specification;
        if (aspec === spec) {
          values.push(i);
        } else {
          specValues.push(values);
          spec = aspec;
          values = [];
          values.push(i);
        }
      }
      specValues.push(values);

      // 根据临时规格列表生产货品规格
      // 算法基于 https://blog.csdn.net/tyhj_sf/article/details/53893125
      var productsIndex = 0;
      var products = [];
      var combination = [];
      var n = specValues.length;
      for (var s = 0; s < n; s++) {
        combination[s] = 0;
      }
      var index = 0;
      var isContinue = false;
      do {
        var specifications = [];
        for (var x = 0; x < n; x++) {
          var z = specValues[x][combination[x]];
          specifications.push(this.newSpecifications[z].value);
        }
        products[productsIndex] = {
          id: productsIndex,
          specifications: specifications,
          price: this.goods.counterPrice,
          number: 0,
          url: '',
          addTime: '',
          deleted: false,
          goodsId: this.goods.id,
          updateTime: '',
          minOrderNum: 0
        };
        productsIndex++;
        index++;
        combination[n - 1] = index;
        for (var j = n - 1; j >= 0; j--) {
          if (combination[j] >= specValues[j].length) {
            combination[j] = 0;
            index = 0;
            if (j - 1 >= 0) {
              combination[j - 1] = combination[j - 1] + 1;
            }
          }
        }
        isContinue = false;
        for (var p = 0; p < n; p++) {
          if (combination[p] !== 0) {
            isContinue = true;
          }
        }
      } while (isContinue);

      // 新生成的商品：其商品规格值和以前商品规格值相同，则赋值
      for (var x1 = 0; x1 < products.length; x1++) {
        for (var y = 0; y < this.newProducts.length; y++) {
          if (products[x1].specifications.join(',') === this.newProducts[y].specifications.join(',')) {
            products[x1].number = this.newProducts[y].number;
            break;
          }
        }
      }
      this.newProducts = products;
    },
    // 商品库存:点击设置按钮事件
    handleProductShow: function handleProductShow(row) {
      this.productForm = Object.assign({}, row);
      this.productVisiable = true;
    },
    // 商品库存:图片上传成功回调事件
    uploadProductUrl: function uploadProductUrl(response) {
      this.productForm.url = response.data.url;
    },
    // 点击确认修改库存按钮事件
    handleProductEdit: function handleProductEdit() {
      this.productForm.updateTime = '';
      for (var i = 0; i < this.newProducts.length; i++) {
        var v = this.newProducts[i];
        if (v.id === this.productForm.id) {
          this.newProducts.splice(i, 1, this.productForm);
          break;
        }
      }
      this.productVisiable = false;
    },
    // 商品库存：同步库存
    handleSynStock: function handleSynStock() {
      var self = this;
      this.newProducts.forEach(function (item, index, array) {
        item.number = self.commonStock;
      });
    },
    // 商品库存：删除某库存
    handleDeleteStock: function handleDeleteStock(row) {
      var index = this.newProducts.indexOf(row);
      this.newProducts.splice(index, 1);
    },
    // 商品参数：确认添加商品参数
    handleAttributeAdd: function handleAttributeAdd() {
      this.attributes.push({
        attribute: '',
        value: ''
      });
    },
    // 商品参数：删除商品参数:数据库就存在的参数将deleted值为true,在编辑页直接添加的直接移除
    handleAttributeDelete: function handleAttributeDelete(row) {
      var index = this.attributes.indexOf(row);
      if (row.id) {
        var newRow = row;
        newRow.deleted = true;
        this.attributes.splice(index, 1, newRow);
      } else {
        this.attributes.splice(index, 1);
      }
    },
    // 取消上架
    handleCancel: function handleCancel() {
      this.$router.push({
        path: '/goods/list'
      });
    },
    // 确认更新商品
    handleEdit: function handleEdit() {
      var _this4 = this;
      this.loading = true;
      // 拖拽图片
      this.goods.gallery = this.file_list;
      // 判断编辑方式去选择信息内容
      if (this.goods.detailType === 1) {
        this.goods.detail = document.querySelector('.detailEdit').outerHTML;
        this.goods.detailTemplate = JSON.stringify(this.goods.detailTemplate);
      } else {
        this.goods.detailTemplate = '';
      }

      // 数据验证
      var isVaild = this.dataIsVaild();
      if (!isVaild) {
        this.loading = false;
        return;
      }

      // 商品参数验证
      var isAttrVaild = this.attrVaild();
      if (!isAttrVaild) {
        this.loading = false;
        return;
      }

      // 修改商品库存时间、商品参数时间，告诉后端这个记录已编辑需要更新
      this.updateGoodsTime();

      // 判断商品库存新增修改删除
      // 避免前端数据显示已删除的库存：用nowProduct代替this.products
      var nowProduct = this.newProducts;
      var oldProducts = JSON.parse(this.oldProducts);
      var addProduct = [];
      for (var i = 0; i < oldProducts.length; i++) {
        var isExt = 0;
        for (var j = 0; j < nowProduct.length; j++) {
          if (oldProducts[i].specifications.join(',') === nowProduct[j].specifications.join(',')) {
            // 新生成的库存和原库存specifications一样，替换成原库存的
            oldProducts[i].updateTime = '';
            oldProducts[i].number = nowProduct[j].number;
            oldProducts[i].price = nowProduct[j].price;
            oldProducts[i].minOrderNum = nowProduct[j].minOrderNum;
            nowProduct[j] = oldProducts[i];
            isExt = 1;
            break;
          }
        }
        // 如果原库存的数据不在新库存中，则原库存该数据已删除：deleted = true
        if (isExt === 0) {
          oldProducts[i].deleted = true;
          addProduct.push(oldProducts[i]);
        }
      }
      // 将原库已删除的数据加入新库存
      addProduct.forEach(function (item, index, array) {
        nowProduct.push(item);
      });

      // 判断商品规格新增修改删除
      var oldSpecifications = JSON.parse(this.oldSpecifications);
      for (var m = 0; m < oldSpecifications.length; m++) {
        var isExt1 = 0;
        for (var n = 0; n < this.newSpecifications.length; n++) {
          if (oldSpecifications[m].specification + ',' + oldSpecifications[m].value === this.newSpecifications[n].specification + ',' + this.newSpecifications[n].value) {
            this.newSpecifications[n] = oldSpecifications[m];
            isExt1 = 1;
            break;
          }
        }
        if (isExt1 === 0) {
          oldSpecifications[m].deleted = true;
          this.newSpecifications.push(oldSpecifications[m]);
        }
      }
      var finalGoods = {
        goods: this.goods,
        specifications: this.newSpecifications,
        products: nowProduct,
        attributes: this.attributes
      };
      (0, _goods.editGoods)(finalGoods).then(function (response) {
        _this4.loading = false;
        _this4.$notify.success({
          title: '成功',
          message: '创建成功'
        });
        _this4.$router.push({
          path: '/goods/list'
        });
      }).catch(function (response) {
        _elementUi.MessageBox.alert('业务错误：' + response.data.errmsg, '警告', {
          confirmButtonText: '确定',
          type: 'error'
        });
      });
    },
    // 商品上架数据验证
    dataIsVaild: function dataIsVaild() {
      if (this.goods.name === undefined || this.goods.name === '') {
        this.$message.error('商品名称不能为空');
        return false;
      }
      if (this.goods.goodsSn === undefined || this.goods.goodsSn === '') {
        this.$message.error('商品款号不能为空');
        return false;
      }
      if (this.goods.goodsSn.length > 30) {
        this.$message.error('商品款号长度过长,最多输入30字符！');
        return false;
      }
      var myreg = /[^\a-\z\A-\Z0-\9_\- /\\]/g;
      if (myreg.test(this.goods.goodsSn)) {
        this.$message.error('商品款号请输入数字、字母、部分符号：_ - \\ / ');
        return false;
      }
      if (this.goods.counterPrice === undefined || this.goods.counterPrice === '') {
        this.$message.error('市场售价不能为空');
        return false;
      }
      if (this.goods.picUrl === undefined || this.goods.picUrl === '') {
        this.$message.error('商品图片不能为空');
        return false;
      }
      if (this.goods.gallery === undefined || this.goods.gallery === '') {
        this.$message.error('宣传画廊不能为空');
        return false;
      }
      if (this.goods.categoryId === undefined || this.goods.categoryId === '') {
        this.$message.error('商品分类不能为空');
        return false;
      }
      if (this.newSpecifications.length === 0) {
        this.$message.error('商品规格不能为空');
        return false;
      }
      if (this.newProducts.length === 0) {
        this.$message.error('商品库存不能为空');
        return false;
      }
      if (this.goods.detail === undefined || this.goods.detail === '') {
        this.$message.error('商品详情不能为空');
        return false;
      }
      return true;
    },
    // 商品参数验证
    attrVaild: function attrVaild() {
      var isOk = true;
      for (var k = 0; k < this.attributes.length; k++) {
        if (this.attributes[k].attribute === '' || this.attributes[k].value === '') {
          this.$message.error('商品参数名称、值不可为空');
          isOk = false;
          break;
        }
      }
      return isOk;
    },
    // 修改商品库存时间、商品参数时间
    updateGoodsTime: function updateGoodsTime() {
      // 这是一个trick，设置updateTime的值为空，告诉后端这个记录已编辑需要更新。
      for (var h = 0; h < this.newProducts.length; h++) {
        this.newProducts[h].updateTime = '';
      }
      for (var g = 0; g < this.attributes.length; g++) {
        if (this.attributes[g].id) {
          this.attributes[g].updateTime = '';
        }
      }
    },
    // 前往编辑商品详情
    gotodetail: function gotodetail() {
      this.detailVisible = true;
    },
    confirmEdit: function confirmEdit() {
      this.detailVisible = false;
    },
    blurInp: function blurInp(e) {
      if (!e.row.minOrderNum && e.row.minOrderNum !== 0) {
        this.newProducts[e.$index].minOrderNum = 0;
      }
    } // getSellHeight(ev) {
    //   this.sellHeight = ev;
    // }
  }
};