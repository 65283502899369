"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.sort");
var _selected = require("@/api/selected");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'MarketTemplateOne',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      // 入口用户列表
      listLoading: false,
      list: [],
      total: 0,
      listQuery: {
        userId: undefined,
        page: 1,
        limit: 20,
        sort: 'id',
        order: 'desc'
      },
      // 选择用户
      selectDialogVisiable: false,
      selectLoading: false,
      selectList: [],
      selectTotal: 0,
      selectQuery: {
        page: 1,
        limit: 20,
        sort: 'id',
        order: 'desc',
        username: '',
        mobile: ''
      },
      idKey: 'id',
      multipleSelection: [],
      multipleSelectionAll: [],
      multipleSelectionAllConstStr: '[]'
    };
  },
  computed: {},
  created: function created() {
    this.getUserList();
  },
  methods: {
    // 获取入口用户列表
    getUserList: function getUserList() {
      var _this = this;
      this.listLoading = true;
      (0, _selected.selectedUserList)({
        page: this.listQuery.page,
        limit: this.listQuery.limit,
        sort: this.listQuery.sort,
        order: this.listQuery.order
      }).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function (response) {
        _this.total = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    // 删除用户
    handleRemove: function handleRemove(row) {
      var _this2 = this;
      this.$confirm('是否要删除该商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _selected.selectedUserDelete)({
          id: row.id
        }).then(function (res) {
          _this2.getUserList();
          _this2.$notify.success({
            title: '成功',
            message: '操作成功'
          });
        }).catch(function (response) {
          _this2.$notify.error({
            title: '失败',
            message: response.data.errmsg
          });
        });
      });
    },
    // 选择用户弹窗
    selectUsers: function selectUsers() {
      var that = this;
      that.selectQuery.page = 1;
      that.selectList = [];
      that.selectTotal = 0;
      that.selectDialogVisiable = true;
      that.getSelectList();
    },
    // 选择用户：查询
    handleFilterSelect: function handleFilterSelect() {
      var that = this;
      // 改变页的时候调用一次
      that.changePageCoreRecordData().then(function (val) {
        that.selectQuery.page = 1;
        that.getSelectList();
      });
    },
    // 选择用户:用户列表页码、页数改变
    handlePaginChange: function handlePaginChange() {
      var that = this;
      // 改变页的时候调用一次
      that.changePageCoreRecordData().then(function (val) {
        that.getSelectList();
      });
    },
    // 选择用户：获取用户列表
    getSelectList: function getSelectList() {
      var _this3 = this;
      this.selectLoading = true;
      (0, _selected.userList)({
        page: this.selectQuery.page,
        limit: this.selectQuery.limit,
        sort: this.selectQuery.sort,
        order: this.selectQuery.order,
        username: this.selectQuery.username,
        mobile: this.selectQuery.mobile
      }).then(function (response) {
        _this3.selectList = response.data.data.list;
        _this3.selectTotal = response.data.data.total;
        _this3.selectLoading = false;
        setTimeout(function () {
          _this3.setSelectRow();
        }, 200);
      }).catch(function (response) {
        _this3.selectList = [];
        _this3.selectTotal = 0;
        _this3.selectLoading = false;
      });
    },
    // 多选框是否可用
    isDisabled: function isDisabled(row, index) {
      if (row.selected) {
        return 0;
      } else {
        return 1;
      }
    },
    // 选择用户：多选改变事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 选择用户：取消选择
    handleCancelSelect: function handleCancelSelect() {
      this.multipleSelectionAll = [];
      this.multipleSelectionAllConstStr = '[]';
      this.selectDialogVisiable = false;
    },
    // 选择用户：确定选择
    handleSubmitSelect: function handleSubmitSelect() {
      var _this4 = this;
      // 改变页的时候调用一次
      var that = this;
      this.changePageCoreRecordData().then(function (val) {
        that.multipleSelection = [];
        that.multipleSelectionAllConstStr = JSON.stringify(that.multipleSelectionAll);
        if (that.multipleSelectionAll.length === 0) {
          _this4.$message.error('请选择用户');
          return;
        }
        var userList = [];
        that.multipleSelectionAll.forEach(function (item, index, array) {
          userList.push(item.id);
        });
        // 接口返回成功后清空已选列表、关闭弹窗
        (0, _selected.selectedUserAdd)({
          userList: userList
        }).then(function (response) {
          that.multipleSelectionAll = [];
          that.multipleSelectionAllConstStr = '[]';
          that.selectDialogVisiable = false;
          that.getUserList();
        }).catch(function (response) {});
      });
    },
    setSelectRow: function setSelectRow() {
      if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
        return;
      }
      var idKey = this.idKey;
      var selectAllIds = [];
      this.multipleSelectionAll.forEach(function (row) {
        selectAllIds.push(row[idKey]);
      });
      this.$refs.multipleTable.clearSelection();
      for (var i = 0; i < this.selectList.length; i++) {
        if (selectAllIds.indexOf(this.selectList[i][idKey]) >= 0) {
          this.$refs.multipleTable.toggleRowSelection(this.selectList[i], true);
        }
      }
    },
    changePageCoreRecordData: function changePageCoreRecordData() {
      var _this5 = this;
      return new Promise(function (resolve, reject) {
        var idKey = _this5.idKey;
        if (_this5.multipleSelectionAll.length <= 0) {
          var selectGoodIds = [];
          _this5.multipleSelection.forEach(function (row) {
            selectGoodIds.push({
              id: row[idKey]
            });
          });
          _this5.multipleSelectionAll = selectGoodIds;
          resolve();
          return;
        }
        var selectAllIds = [];
        _this5.multipleSelectionAll.forEach(function (row) {
          selectAllIds.push(row[idKey]);
        });
        var selectIds = [];
        _this5.multipleSelection.forEach(function (row) {
          selectIds.push(row[idKey]);
          if (selectAllIds.indexOf(row[idKey]) < 0) {
            _this5.multipleSelectionAll.push({
              id: row[idKey]
            });
          }
        });
        var noSelectIds = [];
        _this5.selectList.forEach(function (row) {
          if (selectIds.indexOf(row[idKey]) < 0) {
            noSelectIds.push(row[idKey]);
          }
        });
        noSelectIds.forEach(function (id) {
          if (selectAllIds.indexOf(id) >= 0) {
            for (var i = 0; i < _this5.multipleSelectionAll.length; i++) {
              if (_this5.multipleSelectionAll[i][idKey] === id) {
                _this5.multipleSelectionAll.splice(i, 1);
                break;
              }
            }
          }
        });
        resolve();
      });
    }
  }
};