"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _user = require("@/api/user");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Secondary package based on el-pagination
var _default = exports.default = {
  name: 'UserAddress',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        name: undefined,
        userId: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      downloadLoading: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _user.listAddress)(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleDownload: function handleDownload() {
      var _this2 = this;
      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['地址ID', '用户ID', '收货人', '手机号', '省', '市', '区', '地址', '是否默认'];
        var filterVal = ['id', 'userId', 'name', 'tel', 'province', 'city', 'county', 'addressDetail', 'isDefault'];
        excel.export_json_to_excel2(tHeader, _this2.list, filterVal, '用户地址信息');
        _this2.downloadLoading = false;
      });
    }
  }
};