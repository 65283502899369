"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchReceptAftersale = batchReceptAftersale;
exports.batchRejectAftersale = batchRejectAftersale;
exports.detailAftersale = detailAftersale;
exports.listAftersale = listAftersale;
exports.receptAftersale = receptAftersale;
exports.refundAftersale = refundAftersale;
exports.rejectAftersale = rejectAftersale;
exports.rejectAndShip = rejectAndShip;
var _request = _interopRequireDefault(require("@/utils/request"));
function listAftersale(query) {
  return (0, _request.default)({
    url: '/aftersale/list',
    method: 'get',
    params: query
  });
}
function receptAftersale(data) {
  return (0, _request.default)({
    url: '/aftersale/recept',
    method: 'post',
    data: data
  });
}
function batchReceptAftersale(data) {
  return (0, _request.default)({
    url: '/aftersale/batch-recept',
    method: 'post',
    data: data
  });
}
function rejectAftersale(data) {
  return (0, _request.default)({
    url: '/aftersale/reject',
    method: 'post',
    data: data
  });
}
function batchRejectAftersale(data) {
  return (0, _request.default)({
    url: '/aftersale/batch-reject',
    method: 'post',
    data: data
  });
}
function refundAftersale(data) {
  return (0, _request.default)({
    url: '/aftersale/refund',
    method: 'post',
    data: data
  });
}
function detailAftersale(data) {
  return (0, _request.default)({
    url: '/aftersale/detail',
    method: 'post',
    data: data
  });
}
function rejectAndShip(data) {
  return (0, _request.default)({
    url: '/aftersale/rejectAndShip',
    method: 'post',
    data: data
  });
}