"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/**
 * @author LeeYunxiang
 * @description 为了方便上传图片组件可拖拽排序，不改变饿了么插件的逻辑，只做视图层的展示
 * @param {Array} list 图片数组
 * @param {Number} limit 最多可上传几张图片
 * @param {String} action 上传接口地址
 * @param {Boolean} multiple 是否批量上传
 * @param {Function} beforeUpload 上传之前的回调，用于校验
 * @param {Function} onSuccess 上传成功的回调函数
 * @param {Function} onError 上传失败的回调函数
 * @param {String} accept 上传图片格式限制
 **/
var _default2 = exports.default = {
  name: 'ImageDrag',
  components: {
    draggable: _vuedraggable.default
  },
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    limit: {
      type: Number,
      default: 0
    },
    multiple: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: ''
    },
    beforeUpload: {
      type: Function,
      default: function _default() {}
    },
    onError: {
      type: Function,
      default: function _default() {}
    },
    onSuccess: {
      type: Function,
      default: function _default() {}
    },
    accept: {
      type: String,
      default: '.jpg, .jpeg, .png, .gif'
    }
  },
  data: function data() {
    return {
      // 展示图片列表
      banner_list: [],
      // 拖拽图片列表
      file_list: [],
      // 开启拖拽
      drag_open: false,
      // 查看大图
      dialogImageVisible: false,
      dialogImageUrl: ''
    };
  },
  computed: {
    // 验证权限：头部信息
    headers: function headers() {
      return {
        Authorization: (0, _auth.getToken)()
      };
    }
  },
  watch: {
    list: function list(arr) {
      if (arr.length > this.limit && this.limit !== 0) {
        this.$message.warning("\u5F53\u524D\u6700\u591A\u53EF\u4E0A\u4F20".concat(this.limit, "\u5F20\u56FE\u7247"));
        return false;
      }
      this.banner_list = arr.map(function (url) {
        var obj = {
          url: url,
          is_hover: false
        };
        return obj;
      });
    }
  },
  mounted: function mounted() {
    this.banner_list = this.list.map(function (url) {
      var obj = {
        url: url,
        is_hover: false
      };
      return obj;
    });
  },
  methods: {
    // 删除图片
    deleteImage: function deleteImage(i) {
      this.banner_list.splice(i, 1);
      this.$emit('update', this.banner_list.map(function (item) {
        return item.url;
      }));
    },
    // 开启拖拽
    openDrag: function openDrag() {
      this.file_list = JSON.parse(JSON.stringify(this.banner_list));
      this.drag_open = true;
    },
    // 取消拖拽
    cancle: function cancle() {
      this.banner_list = this.file_list;
      this.drag_open = false;
    },
    // 保存
    save: function save() {
      this.$emit('update', this.banner_list.map(function (item) {
        return item.url;
      }));
      this.drag_open = false;
    },
    // 查看
    handlePictureCardPreview: function handlePictureCardPreview(i) {
      this.dialogImageUrl = this.banner_list[i].url;
      this.dialogImageVisible = true;
    }
  }
};