"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = require("element-ui");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Login',
  data: function data() {
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('管理员密码长度应大于6'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{
          required: true,
          message: '管理员账户不允许为空',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '管理员密码不允许为空',
          trigger: 'blur'
        }, {
          validator: validatePassword,
          trigger: 'blur'
        }]
      },
      passwordType: 'password',
      loading: false
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created: function created() {
    // token 过期提示
    this.point();
    console.log(process.env);
    // window.addEventListener('hashchange', this.afterQRScan)
  },
  destroyed: function destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    handleLogin: function handleLogin() {
      var _this = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid && !_this.loading) {
          _this.loading = true;
          _this.$store.dispatch('LoginByUsername', _this.loginForm).then(function () {
            _this.loading = false;
            _this.$router.push({
              path: _this.redirect || '/'
            });
          }).catch(function (response) {
            _this.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    point: function point() {
      var point = _jsCookie.default.get('point') !== undefined;
      if (point) {
        (0, _elementUi.Notification)({
          title: '提示',
          message: '当前登录状态已过期，请重新登录！',
          type: 'warning',
          duration: 5000
        });
        _jsCookie.default.remove('point');
      }
    }
  }
};