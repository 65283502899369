"use strict";

var _interopRequireWildcard = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/core-js/modules/es6.array.iterator.js");
require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/core-js/modules/es6.promise.js");
require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/core-js/modules/es6.object.assign.js");
require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
var _vcolorpicker = _interopRequireDefault(require("vcolorpicker"));
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("./icons");
require("./permission");
var filters = _interopRequireWildcard(require("./filters"));
var _index2 = _interopRequireDefault(require("@/directive/permission/index.js"));
var _print = _interopRequireDefault(require("@/utils/print"));
// A modern alternative to CSS resets

// 基于vue的颜色选择器

_vue.default.use(_vcolorpicker.default);

// global css

// icon
// permission control

// global filters

// 权限判断指令

// 打印

_vue.default.use(_print.default);
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium' // set element-ui default size
});
_vue.default.directive('permission', _index2.default);

// register global utility filters.
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});