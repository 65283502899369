"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchDeleteNotice = batchDeleteNotice;
exports.createNotice = createNotice;
exports.deleteNotice = deleteNotice;
exports.listNotice = listNotice;
exports.readNotice = readNotice;
exports.updateNotice = updateNotice;
var _request = _interopRequireDefault(require("@/utils/request"));
function listNotice(query) {
  return (0, _request.default)({
    url: '/notice/list',
    method: 'get',
    params: query
  });
}
function createNotice(data) {
  return (0, _request.default)({
    url: '/notice/create',
    method: 'post',
    data: data
  });
}
function readNotice(query) {
  return (0, _request.default)({
    url: '/notice/read',
    method: 'get',
    params: query
  });
}
function updateNotice(data) {
  return (0, _request.default)({
    url: '/notice/update',
    method: 'post',
    data: data
  });
}
function deleteNotice(data) {
  return (0, _request.default)({
    url: '/notice/delete',
    method: 'post',
    data: data
  });
}
function batchDeleteNotice(data) {
  return (0, _request.default)({
    url: '/notice/batch-delete',
    method: 'post',
    data: data
  });
}