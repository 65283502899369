var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pic_base" },
    [
      _vm._l(_vm.children, function (child, index) {
        return [
          child.type == "text"
            ? _c("inner-text", { key: index, attrs: { detail: child } })
            : _c("pic-img-base", { key: index, attrs: { detail: child } }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }