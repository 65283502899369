var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "section",
        { ref: "print", staticClass: "main-form" },
        [
          _c("div", { staticStyle: { margin: "10px 0px 5px 0px" } }, [
            _vm._v("售后商品"),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.afterGoods,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "商品图片", prop: "pic" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          attrs: { src: scope.row.pic, width: "40" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品名称",
                  prop: "goodsName",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "商品编号", prop: "goodsSn" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品规格",
                  prop: "specifications",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.specifition.join(",")))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "商品价格", prop: "price" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "商品数量", prop: "number" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "小计", prop: "amount" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { margin: "10px 0px 5px 0px" } }, [
            _vm._v("售后信息"),
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            { attrs: { data: _vm.afterDetail, "label-position": "left" } },
            [
              _c("el-form-item", { attrs: { label: "售后编号" } }, [
                _c("span", { staticStyle: { padding: "10px" } }, [
                  _vm._v(_vm._s(_vm.afterDetail.aftersaleSn)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "申请状态" } }, [
                _c("span", { staticStyle: { padding: "10px" } }, [
                  _vm._v(
                    _vm._s(_vm._f("orderStatusFilter")(_vm.afterDetail.status))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "订单编号" } }, [
                _c("span", { staticStyle: { padding: "10px" } }, [
                  _vm._v(_vm._s(_vm.afterDetail.orderSn)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "申请时间" } }, [
                _c("span", { staticStyle: { padding: "10px" } }, [
                  _vm._v(_vm._s(_vm.afterDetail.addTime)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "用户昵称" } }, [
                _c("span", { staticStyle: { padding: "10px" } }, [
                  _vm._v(_vm._s(_vm.afterDetail.nickname)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "收货人" } }, [
                _c("span", { staticStyle: { padding: "10px" } }, [
                  _vm._v(_vm._s(_vm.afterDetail.consignee)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "收货电话" } }, [
                _c("span", { staticStyle: { padding: "10px" } }, [
                  _vm._v(_vm._s(_vm.afterDetail.mobile)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "收货地址" } }, [
                _c("span", { staticStyle: { padding: "10px" } }, [
                  _vm._v(_vm._s(_vm.afterDetail.address)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "售后类型" } }, [
                _c("span", { staticStyle: { padding: "10px" } }, [
                  _vm._v(
                    _vm._s(_vm._f("afterTypesFilter")(_vm.afterDetail.type))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "售后原因" } }, [
                _c("span", { staticStyle: { padding: "10px" } }, [
                  _vm._v(_vm._s(_vm.afterDetail.reason)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "凭证" } },
                _vm._l(_vm.afterDetail.pictures, function (pic) {
                  return _c("img", {
                    key: pic,
                    staticClass: "gallery",
                    attrs: { src: pic },
                  })
                }),
                0
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "退款金额" } }, [
                _c("span", { staticStyle: { padding: "10px" } }, [
                  _vm._v(_vm._s(_vm.afterDetail.amount)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "处理备注" } }, [
                _c(
                  "span",
                  { staticStyle: { display: "block", padding: "10px" } },
                  [
                    _vm._v("\n          备注：\n          "),
                    _c("el-input", {
                      staticClass: "input-width",
                      model: {
                        value: _vm.afterDetail.reply,
                        callback: function ($$v) {
                          _vm.$set(_vm.afterDetail, "reply", $$v)
                        },
                        expression: "afterDetail.reply",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: " 商家收货地址" } }, [
                _c(
                  "span",
                  { staticStyle: { display: "block", padding: "10px" } },
                  [
                    _vm._v("\n          姓名：\n          "),
                    _c("el-input", {
                      staticClass: "input-width",
                      model: {
                        value: _vm.afterDetail.sellerConsignee,
                        callback: function ($$v) {
                          _vm.$set(_vm.afterDetail, "sellerConsignee", $$v)
                        },
                        expression: "afterDetail.sellerConsignee",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticStyle: { display: "block", padding: "10px" } },
                  [
                    _vm._v("\n          电话：\n          "),
                    _c("el-input", {
                      staticClass: "input-width",
                      model: {
                        value: _vm.afterDetail.sellerMobile,
                        callback: function ($$v) {
                          _vm.$set(_vm.afterDetail, "sellerMobile", $$v)
                        },
                        expression: "afterDetail.sellerMobile",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticStyle: { display: "block", padding: "10px" } },
                  [
                    _vm._v("\n          地址：\n          "),
                    _c("el-input", {
                      staticClass: "input-width",
                      model: {
                        value: _vm.afterDetail.sellerAddress,
                        callback: function ($$v) {
                          _vm.$set(_vm.afterDetail, "sellerAddress", $$v)
                        },
                        expression: "afterDetail.sellerAddress",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.show
        ? _c(
            "div",
            {
              staticClass: "op-container",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.agree } }, [
                _vm._v("同意申请"),
              ]),
              _vm._v(" "),
              _vm.afterDetail.type != 0
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.refund } },
                    [_vm._v("拒绝申请")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.afterDetail.type == 0
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleShip },
                    },
                    [_vm._v("发货")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "op-container" },
            [
              _c("el-button", { on: { click: _vm.returnAftersale } }, [
                _vm._v("返回"),
              ]),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.shipDialogVisible, title: "发货" },
          on: {
            "update:visible": function ($event) {
              _vm.shipDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "shipForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.shipRules,
                model: _vm.shipForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "快递公司", prop: "shipChannel" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.shipForm.shipChannel,
                        callback: function ($$v) {
                          _vm.$set(_vm.shipForm, "shipChannel", $$v)
                        },
                        expression: "shipForm.shipChannel",
                      },
                    },
                    _vm._l(_vm.channels, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "快递编号", prop: "shipSn" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.shipForm.shipSn,
                      callback: function ($$v) {
                        _vm.$set(_vm.shipForm, "shipSn", $$v)
                      },
                      expression: "shipForm.shipSn",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.shipDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmShip } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }