"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _topic = require("@/api/topic");
var _goods = require("@/api/goods");
var _storage = require("@/api/storage");
var _tinymceVue = _interopRequireDefault(require("@tinymce/tinymce-vue"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Secondary package based on el-pagination
var _default = exports.default = {
  name: 'ProTopicEdit',
  components: {
    Editor: _tinymceVue.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      uploadPath: _storage.uploadPath,
      id: 0,
      topic: {},
      goodsList: [],
      addVisiable: false,
      list: [],
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 5,
        id: undefined,
        name: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      selectedlist: [],
      rules: {
        title: [{
          required: true,
          message: '专题标题不能为空',
          trigger: 'blur'
        }],
        subtitle: [{
          required: true,
          message: '专题子标题不能为空',
          trigger: 'blur'
        }],
        content: [{
          required: true,
          message: '专题内容不能为空',
          trigger: 'blur'
        }],
        price: [{
          required: true,
          message: '专题低价不能为空',
          trigger: 'blur'
        }]
      },
      editorInit: {
        language: 'zh_CN',
        convert_urls: false,
        height: 500,
        plugins: ['advlist anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount'],
        toolbar: ['searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample', 'hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen'],
        images_upload_handler: function images_upload_handler(blobInfo, success, failure) {
          var formData = new FormData();
          formData.append('file', blobInfo.blob());
          (0, _storage.createStorage)(formData).then(function (res) {
            success(res.data.data.url);
          }).catch(function () {
            failure('上传失败，请重新上传');
          });
        }
      }
    };
  },
  computed: {
    headers: function headers() {
      return {
        'Authorization': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    if (this.$route.query.id == null) {
      return;
    }
    this.id = this.$route.query.id;
    this.getTopic();
  },
  methods: {
    getTopic: function getTopic() {
      var _this = this;
      this.listLoading = true;
      (0, _topic.readTopic)({
        id: this.id
      }).then(function (response) {
        _this.topic = response.data.data.topic;
        _this.goodsList = response.data.data.goodsList;
        _this.listLoading = false;
      }).catch(function () {
        _this.topic = {};
        _this.goodsList = [];
        _this.listLoading = false;
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _goods.listGoods)(this.listQuery).then(function (response) {
        _this2.list = response.data.data.list;
        _this2.total = response.data.data.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.list = [];
        _this2.total = 0;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectedlist = val;
    },
    uploadPicUrl: function uploadPicUrl(response) {
      this.topic.picUrl = response.data.url;
    },
    handleCreate: function handleCreate() {
      this.listQuery = {
        page: 1,
        limit: 5,
        id: undefined,
        name: undefined,
        sort: 'add_time',
        order: 'desc'
      };
      this.list = [];
      this.total = 0;
      this.selectedlist = [];
      this.addVisiable = true;
    },
    confirmAdd: function confirmAdd() {
      var _this3 = this;
      var newGoodsIds = [];
      var newGoodsList = [];
      this.selectedlist.forEach(function (item) {
        var id = item.id;
        var found = false;
        _this3.topic.goods.forEach(function (goodsId) {
          if (id === goodsId) {
            found = true;
          }
        });
        if (!found) {
          newGoodsIds.push(id);
          newGoodsList.push(item);
        }
      });
      if (newGoodsIds.length > 0) {
        this.topic.goods = this.topic.goods.concat(newGoodsIds);
        this.goodsList = this.goodsList.concat(newGoodsList);
      }
      this.addVisiable = false;
    },
    handleDelete: function handleDelete(row) {
      for (var index = 0; index < this.topic.goods.length; index++) {
        if (row.id === this.topic.goods[index]) {
          this.topic.goods.splice(index, 1);
        }
      }
      for (var index2 = 0; index2 < this.goodsList.length; index2++) {
        if (row.id === this.goodsList[index2].id) {
          this.goodsList.splice(index2, 1);
        }
      }
    },
    handleCancel: function handleCancel() {
      this.$router.push({
        path: '/promotion/topic'
      });
    },
    handleConfirm: function handleConfirm() {
      var _this4 = this;
      this.$refs['topic'].validate(function (valid) {
        if (valid) {
          (0, _topic.updateTopic)(_this4.topic).then(function (response) {
            _this4.$router.push({
              path: '/promotion/topic'
            });
          }).catch(function (response) {
            _this4.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    }
  }
};