"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _log = require("@/api/log");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var typeMap = {
  0: '一般操作',
  1: '安全操作',
  2: '订单操作',
  3: '其他操作'
};
var _default = exports.default = {
  name: 'SysLog',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    typeFilter: function typeFilter(type) {
      return typeMap[type];
    }
  },
  data: function data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        name: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      rules: {
        name: [{
          required: true,
          message: '角色名称不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _log.listLog)(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    }
  }
};