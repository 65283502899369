var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-badge", { attrs: { "is-dot": _vm.hasNotice } }, [
        _c("i", { staticClass: "el-icon-bell", on: { click: _vm.click } }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }