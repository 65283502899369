var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.dataForm,
            "status-icon": "",
            "label-width": "300px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "运费满减所需最低消费",
                prop: "litemall_express_freight_min",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.dataForm.litemall_express_freight_min,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "litemall_express_freight_min", $$v)
                  },
                  expression: "dataForm.litemall_express_freight_min",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "运费满减不足所需运费",
                prop: "litemall_express_freight_value",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.dataForm.litemall_express_freight_value,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dataForm,
                      "litemall_express_freight_value",
                      $$v
                    )
                  },
                  expression: "dataForm.litemall_express_freight_value",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.update } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }