"use strict";

var _interopRequireDefault = require("/home/jenkinsHome/jenkins/workspace/sihong_test_frontend_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteGroupon = deleteGroupon;
exports.editGroupon = editGroupon;
exports.listGroupon = listGroupon;
exports.listRecord = listRecord;
exports.publishGroupon = publishGroupon;
var _request = _interopRequireDefault(require("@/utils/request"));
function listRecord(query) {
  return (0, _request.default)({
    url: '/groupon/listRecord',
    method: 'get',
    params: query
  });
}
function listGroupon(query) {
  return (0, _request.default)({
    url: '/groupon/list',
    method: 'get',
    params: query
  });
}
function deleteGroupon(data) {
  return (0, _request.default)({
    url: '/groupon/delete',
    method: 'post',
    data: data
  });
}
function publishGroupon(data) {
  return (0, _request.default)({
    url: '/groupon/create',
    method: 'post',
    data: data
  });
}
function editGroupon(data) {
  return (0, _request.default)({
    url: '/groupon/update',
    method: 'post',
    data: data
  });
}